var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReducedMotionQuery } from 'libs/a11y/reduced-motion';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var HomeHeroSlider = /** @class */ (function (_super) {
    __extends(HomeHeroSlider, _super);
    function HomeHeroSlider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isPlaying = false;
        _this.activeIndex = 0;
        _this.total = 0;
        return _this;
    }
    HomeHeroSlider.prototype.getDependencies = function () {
        this.reducedMotionQuery = Provider.get(ReducedMotionQuery);
    };
    HomeHeroSlider.prototype.onInit = function () {
        this.controlElement = this.host.querySelector('.hero__control');
        this.sliderElement = this.host.querySelector('.hero__background-slider');
        this.activeIndex = parseInt(this.sliderElement.getAttribute('data-active'), 0);
        this.total =
            this.sliderElement.querySelectorAll('.hero__background').length;
        if (this.controlElement) {
            this.registerListeners();
            if (!this.reducedMotionQuery.shouldNotAnimate()) {
                this.play();
            }
        }
    };
    HomeHeroSlider.prototype.registerListeners = function () {
        var _this = this;
        this.controlElement.addEventListener('click', function () {
            _this.toggle();
        });
    };
    HomeHeroSlider.prototype.toggle = function () {
        if (this.isPlaying) {
            this.pause();
        }
        else {
            this.play();
        }
    };
    HomeHeroSlider.prototype.play = function () {
        var _this = this;
        this.isPlaying = true;
        this.hostClass('hero--playing', true);
        this.interval = setInterval(function () {
            if (_this.isPlaying) {
                _this.next();
            }
        }, 5000);
    };
    HomeHeroSlider.prototype.next = function () {
        this.activeIndex = ++this.activeIndex % this.total;
        this.sliderElement.setAttribute('data-active', this.activeIndex.toString());
    };
    HomeHeroSlider.prototype.pause = function () {
        this.isPlaying = false;
        this.hostClass('hero--playing', false);
        clearInterval(this.interval);
    };
    return HomeHeroSlider;
}(Component));
ComponentRegistry.register('.hero', HomeHeroSlider);
