var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var ResponsiveLabel = /** @class */ (function (_super) {
    __extends(ResponsiveLabel, _super);
    function ResponsiveLabel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mobileLabel = '';
        _this.desktopLabel = '';
        _this.attribute = '';
        _this.mobileLayout = window.matchMedia('(max-width: 768px');
        return _this;
    }
    ResponsiveLabel.prototype.onInit = function () {
        this.readData();
        this.registerResizeListener();
    };
    ResponsiveLabel.prototype.readData = function () {
        this.mobileLabel = this.host.dataset['responsiveLabelMobile'] || '';
        this.desktopLabel = this.host.dataset['responsiveLabelDesktop'] || '';
        this.attribute = this.host.dataset['responsiveLabelAttribute'] || '';
    };
    ResponsiveLabel.prototype.registerResizeListener = function () {
        var _this = this;
        window.addEventListener('resize', function () {
            _this.setLabels();
        });
        this.setLabels();
    };
    ResponsiveLabel.prototype.setLabels = function () {
        var label = '';
        if (this.mobileLayout.matches) {
            label = this.mobileLabel;
        }
        else {
            label = this.desktopLabel;
        }
        if (!this.attribute) {
            this.host.innerText = label;
        }
        else {
            this.host.setAttribute(this.attribute, label);
        }
    };
    return ResponsiveLabel;
}(Component));
export { ResponsiveLabel };
ComponentRegistry.register('[data-responsive-label]', ResponsiveLabel);
