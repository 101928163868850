import { trackingHandler } from './base';
var scrollTracked = false;
(function () {
    window.addEventListener('scroll', function () {
        if (!scrollTracked) {
            var trackSuccessful = trackingHandler.satelliteTrack('Site Scroll Tracking');
            scrollTracked = trackSuccessful;
        }
    });
})();
