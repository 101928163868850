var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MainNavBehavior } from './main-nav-behavior';
var MobileNavBehavior = /** @class */ (function (_super) {
    __extends(MobileNavBehavior, _super);
    function MobileNavBehavior(host, focusService) {
        var _this = _super.call(this, host, focusService) || this;
        _this.header = document.querySelector('.header');
        return _this;
    }
    MobileNavBehavior.prototype.open = function () {
        _super.prototype.open.call(this);
        this.host.classList.add('main-nav--open');
        this.header.dispatchEvent(new CustomEvent('changeSize'));
        this.enableLinks();
    };
    MobileNavBehavior.prototype.close = function () {
        _super.prototype.close.call(this);
        this.host.classList.remove('main-nav--open');
        this.header.dispatchEvent(new CustomEvent('changeSize'));
        this.disableLinks();
    };
    return MobileNavBehavior;
}(MainNavBehavior));
export { MobileNavBehavior };
