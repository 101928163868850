var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { ExpandableController } from 'libs/expandable-controller';
var AccordionItem = /** @class */ (function (_super) {
    __extends(AccordionItem, _super);
    function AccordionItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AccordionItem.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    AccordionItem.prototype.onInit = function () {
        this.getElements();
        this.registerListeners();
        this.setupExpandableController();
    };
    AccordionItem.prototype.getElements = function () {
        this.button = this.host.querySelector('.accordion-item__button');
        this.expandableContainer = this.host.querySelector('.accordion-item__expandable');
        this.contentContainer = this.host.querySelector('.accordion-item__content');
        this.arrow = this.host.querySelector('.accordion-item__arrow');
    };
    AccordionItem.prototype.setupExpandableController = function () {
        var _this = this;
        this.expandableController = new ExpandableController({
            uid: this.componentId.toString(),
            toggler: this.button,
            expandable: this.expandableContainer,
            expandableContent: this.contentContainer,
        });
        this.expandableController.onStateChange.subscribe(function (state) {
            _this.updateHostClass(state.isExpanded);
        });
        this.expandableController.start();
    };
    AccordionItem.prototype.updateHostClass = function (isExpanded) {
        this.hostClass('accordion-item--expanded', isExpanded);
    };
    AccordionItem.prototype.registerListeners = function () {
        var _this = this;
        this.focusService.onFocusChange.subscribe(function (event) {
            if (_this.button === event.target) {
                _this.focusService.showFocusAt(_this.arrow, {
                    padding: 3,
                });
            }
        });
    };
    return AccordionItem;
}(Component));
export { AccordionItem };
ComponentRegistry.register('.accordion-item', AccordionItem);
