var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var RegistrationPage = /** @class */ (function (_super) {
    __extends(RegistrationPage, _super);
    function RegistrationPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegistrationPage.prototype.onInit = function () {
        this.getElements();
        this.addListeners();
    };
    RegistrationPage.prototype.getElements = function () {
        this.domicileElement = this.host.querySelector('.domicile-field');
        this.worksInSwitzerlandElement = this.host.querySelector('.works-in-switzerland-field');
    };
    RegistrationPage.prototype.addListeners = function () {
        var _this = this;
        this.domicileElement.addEventListener('change', function () {
            _this.updateWorksInSwitzerlandFieldVisibility();
        });
        this.updateWorksInSwitzerlandFieldVisibility();
    };
    RegistrationPage.prototype.updateWorksInSwitzerlandFieldVisibility = function () {
        var _a;
        var countryCode = (_a = this.domicileElement.querySelector('input:checked')) === null || _a === void 0 ? void 0 : _a.value;
        var isVisible = countryCode !== 'CH';
        this.worksInSwitzerlandElement.style.display = isVisible ? 'block' : 'none';
    };
    return RegistrationPage;
}(Component));
export { RegistrationPage };
ComponentRegistry.register('.registration-page', RegistrationPage);
