var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as focusTrap from 'focus-trap';
var DEFAULT_OVERLAY_OPTIONS = {
    disableClosing: false,
};
var Overlay = /** @class */ (function () {
    function Overlay() {
        this.options = DEFAULT_OVERLAY_OPTIONS;
    }
    Overlay.prototype._createOverlayElements = function () {
        if (!document.getElementById('overlay')) {
            var template = document.getElementById('overlay-template');
            var templateFragment = document.importNode(template.content, true);
            document.body.appendChild(templateFragment);
            this.overlayContent = document.querySelector('#overlay .overlay__content');
        }
        var overlay = document.querySelector('#overlay .overlay');
        if (this.options.disableClosing) {
            overlay === null || overlay === void 0 ? void 0 : overlay.classList.add('overlay--disable-closing');
        }
        else {
            overlay === null || overlay === void 0 ? void 0 : overlay.classList.remove('overlay--disable-closing');
        }
    };
    Overlay.prototype._loadContent = function (url, selector) {
        var _this = this;
        this._createOverlayElements();
        var request = new XMLHttpRequest();
        request.open('GET', url);
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        request.onload = function () {
            if (request.status === 200) {
                var tempContent = document.createElement('div');
                tempContent.innerHTML = request.responseText;
                var content = selector
                    ? tempContent.querySelector(selector)
                    : tempContent;
                _this.overlayContent.innerHTML = content.innerHTML;
                tempContent.remove();
                _this._attachListeners();
            }
        };
        request.send();
    };
    Overlay.prototype._attachListeners = function () {
        var _this = this;
        if (this.focusTrap) {
            this.focusTrap.updateContainerElements(document.getElementById('overlay'));
        }
        else {
            this.focusTrap = focusTrap.createFocusTrap(document.getElementById('overlay'), {
                onDeactivate: function () {
                    document.getElementById('overlay').remove();
                },
                escapeDeactivates: function () {
                    return !_this.options.disableClosing;
                },
            });
        }
        // Wait until template rendered before activating focus trap
        window.requestAnimationFrame(function () {
            _this.focusTrap.activate();
            // ComponentRegistry.attachAllComponentsUnder(this.overlayContent);
        });
        var closingElements = document.querySelectorAll('[data-js="close-overlay"]');
        var formElements = this.overlayContent.querySelectorAll('form');
        for (var idx = 0; idx < closingElements.length; idx++) {
            var el = closingElements[idx];
            el.addEventListener('click', function (event) {
                event.preventDefault();
                _this.focusTrap.deactivate();
            });
        }
        for (var idx = 0; idx < formElements.length; idx++) {
            var el = formElements[idx];
            el.addEventListener('submit', function (event) {
                event.preventDefault();
                _this._submitOverlayForm(event.currentTarget);
            });
        }
        if (!this.options.disableClosing) {
            document
                .querySelector('#overlay .overlay-shadow')
                .addEventListener('click', function () { return _this.focusTrap.deactivate(); });
        }
    };
    Overlay.prototype._submitOverlayForm = function (form) {
        var _this = this;
        var data = new FormData(form);
        var method = form.getAttribute('method') || 'POST';
        var url = form.getAttribute('action');
        var request = new XMLHttpRequest();
        request.open(method, url);
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        request.onload = function () {
            if (request.status === 200) {
                if (request.getResponseHeader('Content-Type') === 'application/json') {
                    var jsonResponse = JSON.parse(request.response);
                    window.location.href = jsonResponse.redirect_url;
                }
                else {
                    _this.overlayContent.innerHTML = request.response;
                    _this._attachListeners();
                }
            }
        };
        request.send(data);
    };
    Overlay.prototype.loadUrl = function (url, selector, options) {
        this.options = __assign(__assign({}, DEFAULT_OVERLAY_OPTIONS), (options || {}));
        this._loadContent(url, selector);
    };
    Overlay.prototype.loadTemplate = function (id, options) {
        if (options === void 0) { options = {}; }
        if (document.getElementById(id)) {
            this.options = __assign(__assign({}, DEFAULT_OVERLAY_OPTIONS), options);
            this._createOverlayElements();
            var template = document.getElementById(id);
            var templateFragment = document.importNode(template.content, true);
            var comps = templateFragment.querySelectorAll('[data-component-id]');
            for (var idx = 0; idx < comps.length; idx++) {
                comps[idx].removeAttribute('data-component-id');
            }
            this.overlayContent.appendChild(templateFragment);
            this._attachListeners();
        }
    };
    return Overlay;
}());
export { Overlay };
