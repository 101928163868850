import { Subject } from 'rxjs';
var AutoCompleteInputView = /** @class */ (function () {
    function AutoCompleteInputView(host, listId, focusService) {
        this.host = host;
        this.listId = listId;
        this.focusService = focusService;
        this.isListExpanded = false;
        this.onInput$ = new Subject();
        this.onArrowUp$ = new Subject();
        this.onArrowDown$ = new Subject();
        this.onFocus$ = new Subject();
        this.onBlur$ = new Subject();
        this.onEnter$ = new Subject();
        this.getElements();
        this.addListeners();
        this.addA11yAttributes();
    }
    AutoCompleteInputView.prototype.getElements = function () {
        this.input = this.host.querySelector('input');
    };
    AutoCompleteInputView.prototype.addListeners = function () {
        var _this = this;
        this.input.addEventListener('input', function () {
            _this.emitValue();
        });
        this.input.addEventListener('focus', function () {
            _this.onFocus$.next();
        });
        this.input.addEventListener('blur', function () {
            _this.onBlur$.next();
        });
        this.input.addEventListener('keydown', function (event) {
            switch (event.code) {
                case 'ArrowUp':
                    event.preventDefault();
                    _this.onArrowUp$.next();
                    break;
                case 'ArrowDown':
                    event.preventDefault();
                    _this.onArrowDown$.next();
                    break;
                case 'Enter':
                    if (_this.isListExpanded) {
                        event.preventDefault();
                        _this.onEnter$.next();
                    }
                    break;
            }
        });
    };
    AutoCompleteInputView.prototype.addA11yAttributes = function () {
        this.input.setAttribute('role', 'combobox');
        this.input.setAttribute('aria-expanded', 'false');
        this.input.setAttribute('aria-autocomplete', 'list');
        this.input.setAttribute('aria-activedescendant', '');
        this.input.setAttribute('aria-controls', this.listId);
    };
    AutoCompleteInputView.prototype.emitValue = function () {
        this.onInput$.next(this.input.value);
    };
    AutoCompleteInputView.prototype.setValue = function (value) {
        this.input.value = value;
    };
    AutoCompleteInputView.prototype.getValue = function () {
        return this.input.value;
    };
    AutoCompleteInputView.prototype.setExpanded = function (isExpanded) {
        this.isListExpanded = isExpanded;
        this.input.setAttribute('aria-expanded', isExpanded.toString());
    };
    AutoCompleteInputView.prototype.focus = function () {
        this.focusService.showFocusAt(this.input, {
            padding: 4,
        });
    };
    AutoCompleteInputView.prototype.onInput = function () {
        return this.onInput$.asObservable();
    };
    AutoCompleteInputView.prototype.onFocus = function () {
        return this.onFocus$.asObservable();
    };
    AutoCompleteInputView.prototype.onBlur = function () {
        return this.onBlur$.asObservable();
    };
    AutoCompleteInputView.prototype.onArrowUp = function () {
        return this.onArrowUp$.asObservable();
    };
    AutoCompleteInputView.prototype.onArrowDown = function () {
        return this.onArrowDown$.asObservable();
    };
    AutoCompleteInputView.prototype.onEnter = function () {
        return this.onEnter$.asObservable();
    };
    return AutoCompleteInputView;
}());
export { AutoCompleteInputView };
