export function scrollAnimatedTo(targetEl) {
    targetEl === null || targetEl === void 0 ? void 0 : targetEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
}
(function () {
    if (window.location.hash) {
        var target_1 = document.getElementById(window.location.hash.replace('#', ''));
        window.addEventListener('load', function () {
            scrollAnimatedTo(target_1);
        });
    }
})();
