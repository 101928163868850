var ModalOutlet = /** @class */ (function () {
    function ModalOutlet() {
        this.modals = new Map();
        this.isOpen = false;
        this.create();
        this.addListeners();
    }
    ModalOutlet.prototype.create = function () {
        this.host = document.createElement('div');
        this.host.classList.add('modal-outlet');
        this.backdrop = document.createElement('div');
        this.backdrop.classList.add('modal-outlet__backdrop');
        this.host.append(this.backdrop);
    };
    ModalOutlet.prototype.addListeners = function () {
        var _this = this;
        window.addEventListener('keydown', function (event) {
            var pressedEscape = event.code === 'Escape';
            if (_this.isOpen && pressedEscape) {
                _this.closeLastAdded();
            }
        });
    };
    ModalOutlet.prototype.closeLastAdded = function () {
        var lastId = Array.from(this.modals.keys()).pop();
        this.close(lastId);
    };
    ModalOutlet.prototype.appendTo = function (element) {
        element.append(this.host);
    };
    ModalOutlet.prototype.open = function (modal) {
        var modalWrapper = this.wrapModal(modal);
        this.host.append(modalWrapper);
        this.modals.set(modal.id, {
            modal: modal,
            wrapper: modalWrapper,
        });
        this.setIsOpen(true);
        modal.onActivate();
    };
    ModalOutlet.prototype.wrapModal = function (modal) {
        var _this = this;
        var modalWrapper = document.createElement('div');
        modalWrapper.classList.add('modal-wrapper');
        var modalWrapperBackdrop = document.createElement('div');
        modalWrapperBackdrop.classList.add('modal-wrapper__backdrop');
        modalWrapperBackdrop.addEventListener('click', function () {
            _this.close(modal.id);
        }, { once: true });
        modalWrapper.append(modalWrapperBackdrop);
        modalWrapper.append(modal.host);
        return modalWrapper;
    };
    ModalOutlet.prototype.close = function (id) {
        var wrappedModal = this.modals.get(id);
        this.host.removeChild(wrappedModal.wrapper);
        wrappedModal.modal.onDeactivate();
        this.modals.delete(id);
        if (this.modals.size === 0) {
            this.setIsOpen(false);
        }
    };
    ModalOutlet.prototype.setIsOpen = function (isOpen) {
        this.isOpen = isOpen;
        this.host.classList.toggle('modal-outlet--open', this.isOpen);
    };
    return ModalOutlet;
}());
export { ModalOutlet };
