var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var SuccessStoryVideo = /** @class */ (function (_super) {
    __extends(SuccessStoryVideo, _super);
    function SuccessStoryVideo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onPlayerReady = function () {
            _this.showPreview();
        };
        _this.onPlayerStateChange = function (event) {
            if (event.data == YT.PlayerState.ENDED) {
                _this.showPreview();
            }
            else {
                _this.showVideo();
            }
        };
        return _this;
    }
    SuccessStoryVideo.prototype.onInit = function () {
        var _this = this;
        this.videoId = this.host.dataset['youtubeId'];
        this.successStoryVideo = this.host.querySelector('.success_story_hero__video');
        this.preview = this.host.querySelector('.success_story_hero__preview');
        console.log(this.preview);
        if (this.preview) {
            this.preview.addEventListener('click', function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();
                _this.createPlayer().then(function () {
                    _this.player.playVideo();
                    _this.showVideo();
                });
            });
        }
    };
    SuccessStoryVideo.prototype.createPlayer = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.player) {
                resolve();
                return;
            }
            _this.player = new YT.Player(_this.successStoryVideo, {
                videoId: _this.videoId,
                playerVars: {
                    modestbranding: 1,
                    rel: 0,
                    showinfo: 0,
                },
                events: {
                    onReady: function () {
                        resolve();
                        _this.onPlayerReady();
                    },
                    onStateChange: function (event) {
                        _this.onPlayerStateChange(event);
                    },
                },
            });
        });
    };
    SuccessStoryVideo.prototype.showPreview = function () {
        if (this.preview.classList.contains('success_story_hero__preview--hidden')) {
            this.preview.classList.remove('success_story_hero__preview--hidden');
        }
    };
    SuccessStoryVideo.prototype.showVideo = function () {
        if (!this.preview.classList.contains('success_story_hero__preview--hidden')) {
            this.preview.classList.add('success_story_hero__preview--hidden');
        }
    };
    return SuccessStoryVideo;
}(Component));
ComponentRegistry.register('.player_box', SuccessStoryVideo);
