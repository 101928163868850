// import { isIe11 } from 'libs/ie';
import { getCookie, setCookie } from 'libs/cookies';
/**
 * PrivacyService shows the so called Cookie Banner
 * as long as the user didn't click okay.
 */
var PrivacyService = /** @class */ (function () {
    function PrivacyService(overlay) {
        this.overlay = overlay;
        this.cookieName = 'ubs-privacy-accepted';
        if (!this.wasPolicyAccepted()) {
            this.overlay.loadTemplate('privacy-overlay-template', {
                disableClosing: true,
            });
        }
    }
    PrivacyService.prototype.wasPolicyAccepted = function () {
        var cookieValue = getCookie(this.cookieName);
        return cookieValue === 'true';
    };
    PrivacyService.prototype.acceptPrivacyPolicy = function () {
        var _a, _b;
        setCookie(this.cookieName, 'true', 365);
        (_a = this.overlay.focusTrap) === null || _a === void 0 ? void 0 : _a.deactivate();
        (_b = this.host) === null || _b === void 0 ? void 0 : _b.remove();
    };
    PrivacyService.prototype.openPrivacySettings = function () {
        var _a;
        (_a = this.overlay.focusTrap) === null || _a === void 0 ? void 0 : _a.deactivate();
        this.overlay.loadTemplate('privacy-settings-template', {
            disableClosing: !this.wasPolicyAccepted() &&
                !!document.getElementById('privacy-overlay-template'),
        });
    };
    return PrivacyService;
}());
export { PrivacyService };
