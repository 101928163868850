var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Modal } from './modal';
import * as focusTrap from 'focus-trap';
var YesNoModal = /** @class */ (function (_super) {
    __extends(YesNoModal, _super);
    function YesNoModal(id, ref, template) {
        var _this = _super.call(this, id, ref) || this;
        _this.template = template;
        _this.render();
        return _this;
    }
    YesNoModal.prototype.render = function () {
        var _this = this;
        var template = document.getElementById('yes-no-modal-template');
        var modal = template.content.cloneNode(true);
        var content = this.template.content.cloneNode(true);
        var contentOutlet = modal.querySelector('.yes-no-modal__content');
        contentOutlet.appendChild(content);
        var yesButton = modal.querySelector('.yes-no-modal__yes-button');
        yesButton.addEventListener('click', function () {
            _this.ref.setResult({
                hasAccepted: true,
            });
            _this.ref.close();
        });
        var noButton = modal.querySelector('.yes-no-modal__no-button');
        noButton.addEventListener('click', function () {
            _this.ref.setResult({
                hasAccepted: false,
            });
            _this.ref.close();
        });
        this.host.append(modal);
        this.focusTrap = focusTrap.createFocusTrap(this.host, {
            onActivate: function () {
                noButton.focus();
            },
        });
    };
    YesNoModal.prototype.onActivate = function () {
        this.focusTrap.activate();
    };
    YesNoModal.prototype.onDeactivate = function () {
        this.focusTrap.deactivate();
    };
    return YesNoModal;
}(Modal));
export { YesNoModal };
