var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { TooltipFactory } from './tooltip-factory';
import { TooltipService } from './tooltip-service';
var TooltipHost = /** @class */ (function (_super) {
    __extends(TooltipHost, _super);
    function TooltipHost() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.templateId = '';
        _this.position = 'bottom';
        _this.tooltipRef = null;
        _this.onMouseEnter = function () {
            var isDisabled = _this.host.hasAttribute('data-tooltip-disabled');
            if (isDisabled) {
                return;
            }
            _this.show();
        };
        _this.onMouseMove = function (event) {
            if (!_this.tooltipRef) {
                return;
            }
            var x = event.clientX;
            var y = event.clientY;
            var hoveredElement = document.elementFromPoint(x, y);
            var isInHost = _this.host.contains(hoveredElement);
            var isInTooltip = _this.tooltip.contains(hoveredElement);
            if (!(isInHost || isInTooltip)) {
                _this.hide();
            }
        };
        return _this;
    }
    TooltipHost.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
        this.tooltipService = Provider.get(TooltipService);
    };
    TooltipHost.prototype.onInit = function () {
        var _this = this;
        this.readConfig();
        this.createFactory();
        this.createTooltip();
        this.host.addEventListener('mouseenter', this.onMouseEnter);
        document.addEventListener('mousemove', this.onMouseMove);
        this.focusService.onFocusChange.subscribe(function () {
            if (_this.host.contains(document.activeElement)) {
                _this.show();
            }
            else {
                _this.hide();
            }
        });
    };
    TooltipHost.prototype.readConfig = function () {
        this.templateId = this.host.dataset['tooltip'];
        var position = this.host.dataset['tooltipPosition'];
        if (position) {
            this.position = position;
        }
        var tooltipClass = this.host.dataset['tooltipClass'];
        if (tooltipClass) {
            this.tooltipClass = tooltipClass;
        }
    };
    TooltipHost.prototype.createFactory = function () {
        var template = document.getElementById(this.templateId);
        if (template) {
            this.tooltipFactory = new TooltipFactory(template, this.tooltipClass, this.position);
        }
        else {
            throw new Error("Can't find template with id '".concat(this.templateId, "'"));
        }
    };
    TooltipHost.prototype.createTooltip = function () {
        this.tooltip = this.tooltipFactory.create();
        var tooltipId = "tooltip-".concat(this.componentId);
        this.tooltip.setAttribute('id', tooltipId);
        this.host.setAttribute('aria-describedby', tooltipId);
    };
    TooltipHost.prototype.show = function () {
        if (this.tooltipRef) {
            return;
        }
        this.tooltipRef = this.tooltipService.show({
            host: this.host,
            position: this.position,
            element: this.tooltip,
        });
    };
    TooltipHost.prototype.hide = function () {
        var _a;
        (_a = this.tooltipRef) === null || _a === void 0 ? void 0 : _a.hide();
        this.tooltipRef = null;
    };
    return TooltipHost;
}(Component));
export { TooltipHost };
ComponentRegistry.register('[data-tooltip]', TooltipHost);
