var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { ModalService } from '../modal';
import { RecurringProjectSignupModal } from './recurring-project-signup-modal';
var RecurringProjectSignup = /** @class */ (function (_super) {
    __extends(RecurringProjectSignup, _super);
    function RecurringProjectSignup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RecurringProjectSignup.prototype.getDependencies = function () {
        this.modalService = Provider.get(ModalService);
    };
    RecurringProjectSignup.prototype.onInit = function () {
        var _this = this;
        this.host.addEventListener('click', function (event) {
            if (event.target === _this.host) {
                event.preventDefault();
                _this.modalService.open(function (id, ref) {
                    return new RecurringProjectSignupModal(id, ref);
                });
            }
        });
    };
    return RecurringProjectSignup;
}(Component));
ComponentRegistry.register('.recurring_project_signup_btn', RecurringProjectSignup);
