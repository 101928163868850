var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var CompanyEdit = /** @class */ (function (_super) {
    __extends(CompanyEdit, _super);
    function CompanyEdit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.scrollPosition = 0;
        return _this;
    }
    CompanyEdit.prototype.onInit = function () {
        var _this = this;
        if (this.checkHasError()) {
            this.readScrollPosition();
        }
        else {
            this.clearScrollPosition();
        }
        this.host.addEventListener('submit', function (event) {
            event.preventDefault();
            _this.setScrollPosition(window.scrollY);
            var form = event.target;
            form.submit();
        });
    };
    CompanyEdit.prototype.checkHasError = function () {
        if (this.host.querySelector('.form-field__error')) {
            return true;
        }
        return false;
    };
    CompanyEdit.prototype.readScrollPosition = function () {
        var scrollPositionString = sessionStorage.getItem('company-edit-scroll-position');
        if (scrollPositionString) {
            this.scrollPosition = parseInt(scrollPositionString);
            window.scrollTo({
                top: this.scrollPosition,
            });
        }
    };
    CompanyEdit.prototype.clearScrollPosition = function () {
        sessionStorage.removeItem('company-edit-scroll-position');
    };
    CompanyEdit.prototype.setScrollPosition = function (scrollPosition) {
        sessionStorage.setItem('company-edit-scroll-position', scrollPosition.toString());
    };
    return CompanyEdit;
}(Component));
export { CompanyEdit };
ComponentRegistry.register('.company-edit', CompanyEdit);
