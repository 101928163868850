var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var Checkbox = /** @class */ (function (_super) {
    __extends(Checkbox, _super);
    function Checkbox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Checkbox.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    Checkbox.prototype.onInit = function () {
        var _this = this;
        this.getElements();
        this.input.addEventListener('focus', function () { return _this.focus(); });
        this.focusService.onFocusChange.subscribe(function (event) {
            if (_this.input === event.target) {
                _this.focus();
            }
        });
    };
    Checkbox.prototype.getElements = function () {
        this.input = this.host.querySelector('.checkbox__input');
        this.checkmarkElement = this.host.querySelector('.checkbox__checkmark');
        this.errorElement = this.host.querySelector('.form-field__error');
        if (this.errorElement) {
            this.linkErrorText();
        }
    };
    Checkbox.prototype.linkErrorText = function () {
        var errorId = "error_".concat(this.componentId);
        this.errorElement.setAttribute('id', errorId);
        this.input.setAttribute('aria-describedby', errorId);
    };
    Checkbox.prototype.focus = function () {
        if (this.checkmarkElement) {
            this.focusService.showFocusAt(this.checkmarkElement, {
                padding: 3,
            });
        }
    };
    return Checkbox;
}(Component));
export { Checkbox };
ComponentRegistry.register('.checkbox', Checkbox);
