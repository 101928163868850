var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var InputField = /** @class */ (function (_super) {
    __extends(InputField, _super);
    function InputField() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InputField.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    InputField.prototype.onInit = function () {
        this.getElements();
        this.setupAriaDescribedby();
        this.checkIfIsTextarea();
        this.setUpListeners();
        if (this.inputElement === document.activeElement) {
            this.onFocus();
        }
        this.onChange();
    };
    InputField.prototype.getElements = function () {
        this.inputElement = this.host.querySelector('.input-field__input');
        this.helptextElement = this.host.querySelector('.input-field__helptext');
        this.errorElement = this.host.querySelector('.form-field__error');
    };
    InputField.prototype.setupAriaDescribedby = function () {
        if (this.errorElement) {
            this.linkErrorText();
        }
        else if (this.helptextElement) {
            this.linkHelpText();
        }
    };
    InputField.prototype.linkHelpText = function () {
        var helptextId = "helptext_".concat(this.componentId);
        this.helptextElement.setAttribute('id', helptextId);
        this.inputElement.setAttribute('aria-describedby', helptextId);
    };
    InputField.prototype.linkErrorText = function () {
        var errorId = "error_".concat(this.componentId);
        this.errorElement.setAttribute('id', errorId);
        this.inputElement.setAttribute('aria-describedby', errorId);
    };
    InputField.prototype.checkIfIsTextarea = function () {
        this.isTextArea =
            this.inputElement.tagName.toLocaleLowerCase() === 'textarea';
    };
    InputField.prototype.setUpListeners = function () {
        var _this = this;
        this.inputElement.addEventListener('focus', function () { return _this.onFocus(); });
        this.inputElement.addEventListener('blur', function () { return _this.onBlur(); });
        this.inputElement.addEventListener('change', function () { return _this.onChange(); });
        this.maxLength = parseInt(this.inputElement.getAttribute('maxlength') || 'Infinity', 10);
        this.charsLeftEl = this.host.parentElement.querySelector('.chars-left');
        var showsCharsLeft = !!(this.maxLength && this.charsLeftEl);
        if (this.isTextArea) {
            this.inputElement.addEventListener('input', function () {
                if (showsCharsLeft) {
                    _this.updateRemainingCharacters();
                }
                _this.resizeTextarea();
            });
            if (showsCharsLeft) {
                this.updateRemainingCharacters();
            }
        }
        this.focusService.onFocusChange.subscribe(function (event) {
            if (event.target === _this.inputElement) {
                if (_this.isTextArea && showsCharsLeft) {
                    _this.showFocusForTextAreasWithCharsLeftDisplay();
                }
                else {
                    _this.showFocus();
                }
            }
        });
    };
    InputField.prototype.updateRemainingCharacters = function () {
        this.charsLeftEl.innerHTML = (this.maxLength - this.inputElement.value.length).toString();
    };
    InputField.prototype.onFocus = function () {
        this.hostClass('input-field--focus', true);
    };
    InputField.prototype.onBlur = function () {
        this.hostClass('input-field--focus', false);
        this.onChange();
    };
    InputField.prototype.onChange = function () {
        var hasValue = !!this.inputElement.value && this.inputElement.value.length > 0;
        this.hostClass('input-field--filled', hasValue);
        if (this.isTextArea) {
            this.resizeTextarea();
        }
    };
    InputField.prototype.resizeTextarea = function () {
        this.inputElement.style.height =
            Math.max(this.inputElement.scrollHeight, 80) + 'px';
        this.showFocusForTextAreasWithCharsLeftDisplay();
    };
    InputField.prototype.showFocusForTextAreasWithCharsLeftDisplay = function () {
        this.focusService.showFocusAt(this.host.parentElement, {
            padding: 5,
        });
    };
    InputField.prototype.showFocus = function () {
        this.focusService.showFocusAt(this.host, {
            padding: 4,
        });
    };
    return InputField;
}(Component));
ComponentRegistry.register('.input-field', InputField);
