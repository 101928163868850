var DefaultIdGenerator = /** @class */ (function () {
    function DefaultIdGenerator() {
        this.lastId = -1;
    }
    DefaultIdGenerator.prototype.next = function () {
        var newId = this.lastId + 1;
        this.lastId = newId;
        return newId;
    };
    return DefaultIdGenerator;
}());
export { DefaultIdGenerator };
