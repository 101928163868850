var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { Overlay } from '../overlay';
import { trackingHandler } from 'libs/tracking/base';
import { PrivacyService } from './privacy-service';
import { Provider } from 'libs/provider';
/**
 * DataPrivacy Component does the
 * handling of the user preferences concerning the data policies.
 *
 * The componenent is opened within an overlay.
 */
var DataPrivacy = /** @class */ (function (_super) {
    __extends(DataPrivacy, _super);
    function DataPrivacy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DataPrivacy.prototype.getDependencies = function () {
        this.privacyService = Provider.get(PrivacyService);
        this.overlay = Provider.get(Overlay);
    };
    DataPrivacy.prototype.onInit = function () {
        this.allowStatisticsInput = this.host.querySelector('.privacy-settings__setting input[name="privacy_statistics"]');
        this.allowUserPreferencesInput = this.host.querySelector('.privacy-settings__setting input[name="privacy_user_preferences"]');
        this.allowMarketingInput = this.host.querySelector('.privacy-settings__setting input[name="privacy_marketing"]');
        this.setUpListeners();
        this.setInitial();
    };
    DataPrivacy.prototype.setUpListeners = function () {
        var _this = this;
        this.host
            .querySelector('#data-privacy__checkall')
            .addEventListener('click', this.checkAll.bind(this));
        this.host
            .querySelector('#data-privacy__submit')
            .addEventListener('click', this.submit.bind(this));
        var checkboxes = this.host.querySelectorAll('.privacy-settings input[type="checkbox"]');
        for (var i = 0; i < checkboxes.length; i++) {
            var el = checkboxes[i];
            el.addEventListener('change', function () {
                _this.updateRequirements();
            });
        }
    };
    DataPrivacy.prototype.updateRequirements = function () {
        var allowStatistics = this.allowStatisticsInput.checked;
        if (!allowStatistics) {
            this.allowMarketingInput.checked = false;
            this.allowMarketingInput.setAttribute('disabled', 'disabled');
        }
        else {
            this.allowMarketingInput.removeAttribute('disabled');
        }
    };
    DataPrivacy.prototype.setInitial = function () {
        if (trackingHandler.allowStatistics) {
            this.allowStatisticsInput.setAttribute('checked', 'checked');
        }
        if (trackingHandler.allowUserPreferences) {
            this.allowUserPreferencesInput.setAttribute('checked', 'checked');
        }
        if (trackingHandler.allowMarketing) {
            this.allowMarketingInput.setAttribute('checked', 'checked');
        }
        this.updateRequirements();
    };
    DataPrivacy.prototype.checkAll = function () {
        this.allowStatisticsInput.checked = true;
        this.allowUserPreferencesInput.checked = true;
        this.allowMarketingInput.checked = true;
        this.submit();
    };
    DataPrivacy.prototype.submit = function () {
        var _this = this;
        trackingHandler.allowStatistics = this.allowStatisticsInput.checked;
        trackingHandler.allowUserPreferences =
            this.allowUserPreferencesInput.checked;
        trackingHandler.allowMarketing = this.allowMarketingInput.checked;
        this.privacyService.acceptPrivacyPolicy();
        setTimeout(function () {
            _this.overlay.focusTrap.deactivate();
            _this.alertSavedSuccessfully();
        }, 250);
    };
    DataPrivacy.prototype.alertSavedSuccessfully = function () {
        var alertTemplate = document.getElementById('privacy-settings-alert');
        var fragment = document.importNode(alertTemplate.content, true);
        var element = document.createElement('div');
        element.appendChild(fragment);
        var alertOutlet = document.querySelector('.alert-outlet');
        alertOutlet === null || alertOutlet === void 0 ? void 0 : alertOutlet.appendChild(element);
        setTimeout(function () {
            alertOutlet === null || alertOutlet === void 0 ? void 0 : alertOutlet.removeChild(element);
        }, 1000);
    };
    return DataPrivacy;
}(Component));
ComponentRegistry.register('.data-privacy', DataPrivacy);
/**
 * PrivacyOpener Component does the
 * registration of the privacy settings overlay event listener.
 */
var PrivacyOpener = /** @class */ (function (_super) {
    __extends(PrivacyOpener, _super);
    function PrivacyOpener() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PrivacyOpener.prototype.getDependencies = function () {
        this.privacyService = Provider.get(PrivacyService);
        this.overlay = Provider.get(Overlay);
    };
    PrivacyOpener.prototype.onInit = function () {
        var _this = this;
        this.host.addEventListener('click', function (event) {
            event.preventDefault();
            _this.openOverlay();
        });
    };
    PrivacyOpener.prototype.openOverlay = function () {
        this.privacyService.openPrivacySettings();
    };
    return PrivacyOpener;
}(Component));
ComponentRegistry.register('[data-js="open-privacy-settings"]', PrivacyOpener);
