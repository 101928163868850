var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { trackingHandler } from './base';
var FormTracking = /** @class */ (function (_super) {
    __extends(FormTracking, _super);
    function FormTracking() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasInteracted = false;
        return _this;
    }
    FormTracking.prototype.onInit = function () {
        this.readDatasetProperties();
        if (this.hasFormToTrack()) {
            this.updateDigitalData();
            if (this.shouldLogFormSuccess()) {
                this.logFormSuccess();
            }
            else {
                if (this.shouldForceFirstInteraction()) {
                    this.logFirstInteraction();
                }
                else {
                    if (this.isOnFirstStep()) {
                        this.listenForFirstInteraction();
                    }
                    else {
                        this.logStepView();
                    }
                }
            }
        }
    };
    FormTracking.prototype.readDatasetProperties = function () {
        this.formName = this.host.getAttribute('data-form-name');
        this.formStepName = this.host.getAttribute('data-form-step-name');
        this.formStepNumber =
            this.host.getAttribute('data-form-step-number') || '1';
        this.formForceFirstInteraction =
            this.host.getAttribute('data-form-force-first-interaction') === 'true';
        this.formSuccess = this.host.getAttribute('data-form-success') === 'true';
    };
    FormTracking.prototype.hasFormToTrack = function () {
        return !!this.formName;
    };
    FormTracking.prototype.updateDigitalData = function () {
        digitalData.form = {};
        digitalData.form['formName'] = this.formName;
        digitalData.form['formStepName'] = this.formStepName;
    };
    FormTracking.prototype.shouldForceFirstInteraction = function () {
        return this.formForceFirstInteraction;
    };
    FormTracking.prototype.isOnFirstStep = function () {
        return this.formStepNumber == '1';
    };
    FormTracking.prototype.listenForFirstInteraction = function () {
        var _this = this;
        var inputs = this.host.querySelectorAll('input');
        for (var i = 0; i < inputs.length; i++) {
            var el = inputs[i];
            el.addEventListener('keydown', function () {
                _this.logFirstInteraction();
            }, { once: true });
            el.addEventListener('click', function () {
                _this.logFirstInteraction();
            }, { once: true });
        }
    };
    FormTracking.prototype.shouldLogFormSuccess = function () {
        return this.formSuccess;
    };
    FormTracking.prototype.logFirstInteraction = function () {
        if (!this.hasInteracted && this.formStepNumber == '1') {
            this.hasInteracted = true;
            this.logFormStart();
            this.logStepView();
        }
    };
    FormTracking.prototype.logFormStart = function () {
        trackingHandler.satelliteTrack('Site Form Start', digitalData.form);
        // console.log('Site Form Start', digitalData.form);
    };
    FormTracking.prototype.logStepView = function () {
        trackingHandler.satelliteTrack('Site Form Step View', digitalData.form);
        // console.log('Site Form Step View', digitalData.form);
    };
    FormTracking.prototype.logFormSuccess = function () {
        trackingHandler.satelliteTrack('Site Form Completed', digitalData.form);
        // console.log('Site Form Completed', digitalData.form);
    };
    return FormTracking;
}(Component));
ComponentRegistry.register('[data-form-name]', FormTracking);
