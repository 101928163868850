// @ts-ignore
import anime from 'animejs/lib/anime.es.js';
import { Subject } from 'rxjs';
import './expandable-controller.scss';
var ExpandableController = /** @class */ (function () {
    function ExpandableController(args) {
        var _this = this;
        this.isExpanded = false;
        this.togglerId = '';
        this.expandableId = '';
        this.duration = 300;
        this.easing = 'easeOutCubic';
        this.isAnimated = true;
        this.onStateChange$ = new Subject();
        this.onTogglerClick = function (event) {
            event.preventDefault();
            _this.setIsExpanded(!_this.isExpanded);
        };
        this.uid = args.uid;
        this.expandable = args.expandable;
        this.expandableContent = args.expandableContent;
        this.toggler = args.toggler;
    }
    ExpandableController.prototype.start = function () {
        this.initA11y();
        this.addListeners();
        this.setInitialStyles();
        this.addDataAttributes();
    };
    ExpandableController.prototype.initA11y = function () {
        this.expandableId = "expandable-".concat(this.uid);
        this.expandable.setAttribute('role', 'region');
        this.expandable.setAttribute('id', this.expandableId);
        if (this.toggler) {
            this.expandable.setAttribute('aria-labelledby', this.togglerId);
            this.togglerId = "expandable-toggler-".concat(this.uid);
            this.toggler.setAttribute('id', this.togglerId);
            this.toggler.setAttribute('aria-controls', this.expandableId);
            this.toggler.setAttribute('aria-expanded', 'false');
        }
    };
    ExpandableController.prototype.addDataAttributes = function () {
        var _a;
        (_a = this.toggler) === null || _a === void 0 ? void 0 : _a.setAttribute('data-expandable-toggle', '');
        this.expandable.setAttribute('data-expandable', '');
        this.expandableContent.setAttribute('data-expandable-content', '');
    };
    ExpandableController.prototype.addListeners = function () {
        var _a;
        (_a = this.toggler) === null || _a === void 0 ? void 0 : _a.addEventListener('click', this.onTogglerClick);
    };
    ExpandableController.prototype.setInitialStyles = function () {
        if (this.isExpanded) {
            this.addExpandedClass();
        }
        else if (!this.expandable.classList.contains('is-expanded')) {
            this.removeExpandedClass();
        }
    };
    ExpandableController.prototype.setIsExpanded = function (isExpanded) {
        this.isExpanded = isExpanded;
        this.update();
    };
    ExpandableController.prototype.update = function () {
        this.updateA11y();
        this.doAnimation();
        this.emitStateChange();
    };
    ExpandableController.prototype.updateA11y = function () {
        var _a;
        (_a = this.toggler) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-expanded', this.isExpanded.toString());
    };
    ExpandableController.prototype.doAnimation = function () {
        if (this.isExpanded) {
            this.doExpandAnimation();
        }
        else {
            this.doCollapseAnimation();
        }
    };
    ExpandableController.prototype.doExpandAnimation = function () {
        var _this = this;
        this.expandable.style.height = '0';
        this.expandable.style.opacity = '0';
        this.expandable.style.display = 'block';
        var duration = this.isAnimated ? this.duration : 0;
        anime({
            targets: this.expandable,
            duration: duration,
            easing: this.easing,
            height: this.expandableContent.clientHeight,
            opacity: 1,
            complete: function () {
                _this.expandable.style.height = '';
                _this.expandable.style.opacity = '';
                _this.expandable.style.display = '';
                _this.addExpandedClass();
            },
        });
    };
    ExpandableController.prototype.addExpandedClass = function () {
        this.expandable.classList.add('is-expanded');
    };
    ExpandableController.prototype.doCollapseAnimation = function () {
        var _this = this;
        this.expandable.style.height = "".concat(this.expandableContent.clientHeight, "px");
        this.expandable.style.opacity = '1';
        this.expandable.style.display = 'block';
        console.log(this.expandableContent.clientHeight);
        var duration = this.isAnimated ? this.duration : 0;
        anime({
            targets: this.expandable,
            duration: duration,
            easing: this.easing,
            height: 0,
            opacity: 0,
            complete: function () {
                _this.removeExpandedClass();
                _this.expandable.style.height = '';
                _this.expandable.style.opacity = '';
                _this.expandable.style.display = '';
            },
        });
    };
    ExpandableController.prototype.removeExpandedClass = function () {
        this.expandable.classList.remove('is-expanded');
    };
    ExpandableController.prototype.emitStateChange = function () {
        this.onStateChange$.next({
            isExpanded: this.isExpanded,
        });
    };
    ExpandableController.prototype.expand = function (isAnimated) {
        if (isAnimated === void 0) { isAnimated = true; }
        this.isAnimated = isAnimated;
        this.setIsExpanded(true);
        this.isAnimated = true;
    };
    ExpandableController.prototype.collapse = function (isAnimated) {
        if (isAnimated === void 0) { isAnimated = true; }
        this.isAnimated = isAnimated;
        this.setIsExpanded(false);
        this.isAnimated = true;
    };
    Object.defineProperty(ExpandableController.prototype, "onStateChange", {
        get: function () {
            return this.onStateChange$.asObservable();
        },
        enumerable: false,
        configurable: true
    });
    return ExpandableController;
}());
export { ExpandableController };
