var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import * as Hammer from 'hammerjs';
var SuccessStory = /** @class */ (function (_super) {
    __extends(SuccessStory, _super);
    function SuccessStory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SuccessStory.prototype.onInit = function () {
        this.hammerEl = new Hammer(this.host);
        this.slideIndex = 1;
        this.prevButton = this.host.querySelector('.gallery__icon--prev');
        this.nextButton = this.host.querySelector('.gallery__icon--next');
        this.showSlides(this.slideIndex);
        this.setUpListeners();
    };
    SuccessStory.prototype.showSlides = function (n) {
        this.slides = this.host.querySelectorAll('.gallery__image');
        this.totalSlides = this.slides.length;
        for (var i = 0; i < this.totalSlides; i++) {
            this.slides[i].classList.remove('gallery__image--active', 'gallery__image--prev', 'gallery__image--next');
        }
        if (n < 1) {
            n = this.totalSlides;
        }
        var prevIdx = ((n - 1) % this.totalSlides !== 0
            ? (n - 1) % this.totalSlides
            : this.totalSlides) - 1;
        var activeIdx = (n % this.totalSlides !== 0 ? n % this.totalSlides : this.totalSlides) -
            1;
        var nextIdx = ((n + 1) % this.totalSlides !== 0
            ? (n + 1) % this.totalSlides
            : this.totalSlides) - 1;
        this.slides[prevIdx].classList.add('gallery__image--prev');
        this.slides[nextIdx].classList.add('gallery__image--next');
        this.slides[activeIdx].classList.add('gallery__image--active');
        this.host.querySelector('.gallery__item__number').innerHTML =
            activeIdx + 1 + '/' + this.totalSlides;
    };
    SuccessStory.prototype.setUpListeners = function () {
        var _this = this;
        this.prevButton.addEventListener('click', function (evt) {
            evt.preventDefault();
            _this.slideIndex--;
            _this.showSlides(_this.slideIndex);
        });
        this.nextButton.addEventListener('click', function (evt) {
            evt.preventDefault();
            _this.slideIndex++;
            _this.showSlides(_this.slideIndex);
        });
        this.hammerEl.on('swiperight', function () {
            _this.slideIndex--;
            _this.showSlides(_this.slideIndex);
        });
        this.hammerEl.on('swipeleft', function () {
            _this.slideIndex++;
            _this.showSlides(_this.slideIndex);
        });
    };
    return SuccessStory;
}(Component));
ComponentRegistry.register('.gallery', SuccessStory);
