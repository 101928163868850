var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { setupTracking } from 'libs/tracking/link';
import { Modal } from '../modal';
import * as focusTrap from 'focus-trap';
var RecurringProjectSignupModal = /** @class */ (function (_super) {
    __extends(RecurringProjectSignupModal, _super);
    function RecurringProjectSignupModal(id, ref) {
        var _this = _super.call(this, id, ref) || this;
        _this.render();
        return _this;
    }
    RecurringProjectSignupModal.prototype.render = function () {
        var _this = this;
        var template = document.getElementById('recurring-project-signup-modal-template');
        var modal = template.content.cloneNode(true);
        var closeButton = modal.querySelector('.recurring-project-signup-modal-close-button');
        closeButton.addEventListener('click', function () {
            _this.ref.close();
        });
        setupTracking(modal.querySelectorAll('.link,.btn'));
        this.host.append(modal);
        this.focusTrap = focusTrap.createFocusTrap(this.host, {
            onActivate: function () {
                closeButton.focus();
            },
        });
    };
    RecurringProjectSignupModal.prototype.onActivate = function () {
        this.focusTrap.activate();
    };
    RecurringProjectSignupModal.prototype.onDeactivate = function () {
        this.focusTrap.deactivate();
    };
    return RecurringProjectSignupModal;
}(Modal));
export { RecurringProjectSignupModal };
