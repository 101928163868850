var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusNavigator } from 'libs/a11y/focus-navigator';
import { MainNavBehavior } from './main-nav-behavior';
var DesktopNavBehavior = /** @class */ (function (_super) {
    __extends(DesktopNavBehavior, _super);
    function DesktopNavBehavior(host, focusService) {
        var _this = _super.call(this, host, focusService) || this;
        _this.setupArrowKeyListener();
        return _this;
    }
    DesktopNavBehavior.prototype.setupArrowKeyListener = function () {
        var _this = this;
        this.links = this.host.querySelectorAll('.nav-list__link');
        this.focusNavigator = new FocusNavigator(this.links);
        window.addEventListener('keydown', function (event) {
            if (_this.isActive && _this.isOpen) {
                switch (event.code) {
                    case 'Escape':
                        _this.close();
                        _this.navOpener.focus();
                        break;
                    case 'ArrowUp':
                    case 'ArrowRight':
                        event.preventDefault();
                        event.stopPropagation();
                        _this.focusNavigator.previous();
                        break;
                    case 'ArrowDown':
                    case 'ArrowLeft':
                        event.preventDefault();
                        event.stopPropagation();
                        _this.focusNavigator.next();
                        break;
                }
            }
        });
    };
    DesktopNavBehavior.prototype.close = function () {
        _super.prototype.close.call(this);
        this.host.classList.add('main-nav--collapsed');
        this.disableLinks();
        this.navOpener.tabIndex = 0;
    };
    DesktopNavBehavior.prototype.open = function () {
        _super.prototype.open.call(this);
        this.host.classList.remove('main-nav--collapsed');
        this.enableLinks();
        this.focusFirstLink();
        this.navOpener.tabIndex = -1;
    };
    DesktopNavBehavior.prototype.focusFirstLink = function () {
        var firstLink = this.links[0];
        firstLink.focus();
        this.focusNavigator.detectFocusedItem();
    };
    return DesktopNavBehavior;
}(MainNavBehavior));
export { DesktopNavBehavior };
