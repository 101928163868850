"use strict";
(function () {
    var description = document.querySelector('.project_detail__description');
    var more = document.querySelector('.description__more');
    var less = document.querySelector('.description__less');
    if (more) {
        more.addEventListener('click', function () {
            description.classList.add('project_detail__description--more');
        });
    }
    if (less) {
        less.addEventListener('click', function () {
            description.classList.remove('project_detail__description--more');
        });
    }
})();
