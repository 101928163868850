import { getChildComponent, getChildComponents, getComponentForElement, getParentComponent, } from './component-utils';
var Component = /** @class */ (function () {
    function Component(host, componentId) {
        this.host = host;
        this.componentId = componentId;
    }
    Component.prototype.getDependencies = function () {
        // NOTE: empty implementation on purpose
    };
    Component.prototype.onCreate = function () {
        // NOTE: empty implementation on purpose
    };
    Component.prototype.onInit = function () {
        // NOTE: empty implementation on purpose
    };
    Component.prototype.hostClass = function (cssClass, condition) {
        this.host.classList.toggle(cssClass, condition);
    };
    Component.prototype.getParentComponent = function (selector) {
        return getParentComponent(this.host, selector);
    };
    Component.prototype.getChildComponent = function (selector) {
        return getChildComponent(this.host, selector);
    };
    Component.prototype.getChildComponents = function (selector) {
        return getChildComponents(this.host, selector);
    };
    Component.prototype.getComponentForElement = function (element) {
        return getComponentForElement(element);
    };
    return Component;
}());
export { Component };
