import { getCookie, setCookie } from 'libs/cookies';
var Tracking = /** @class */ (function () {
    function Tracking() {
    }
    Object.defineProperty(Tracking.prototype, "allowStatistics", {
        get: function () {
            var value = getCookie('sat_track');
            if (!value) {
                // setCookie('sat_track', 'false', 365);
                return false;
            }
            return value == 'true';
        },
        set: function (value) {
            var prevValue = this.allowStatistics;
            if (value) {
                setCookie('sat_track', 'true', 365);
            }
            else {
                setCookie('sat_track', 'false', -1);
            }
            if (prevValue != value) {
                // Reload is required since <script> will only
                // be added if the cookie is set to true.
                window.location.reload();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tracking.prototype, "allowUserPreferences", {
        get: function () {
            var value = getCookie('user_preference');
            if (!value) {
                // setCookie('user_preference', 'false', 365);
                return false;
            }
            return value == 'true';
        },
        set: function (value) {
            if (value) {
                setCookie('user_preference', 'true', 365);
            }
            else {
                setCookie('user_preference', 'false', -1);
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tracking.prototype, "allowMarketing", {
        get: function () {
            var value = getCookie('allow_marketing');
            if (!value) {
                // setCookie('allow_marketing', 'false', 365);
                return false;
            }
            return value == 'true';
        },
        set: function (value) {
            if (value) {
                setCookie('allow_marketing', 'true', 365);
            }
            else {
                setCookie('allow_marketing', 'false', -1);
            }
        },
        enumerable: false,
        configurable: true
    });
    Tracking.prototype.satelliteTrack = function (event, digitalData) {
        var _this = this;
        if (this.allowStatistics && typeof _satellite !== 'undefined') {
            _satellite.track(event, digitalData || {});
            return true;
        }
        else if (this.allowStatistics && !this.trackingLoadedInterval) {
            this.trackingLoadedInterval = setInterval(function () {
                if (typeof _satellite !== 'undefined') {
                    clearInterval(_this.trackingLoadedInterval);
                    _this.satelliteTrack(event);
                }
            }, 1000);
            return true;
        }
        else {
            return false;
        }
    };
    return Tracking;
}());
export var trackingHandler = new Tracking();
(function () {
    trackingHandler.satelliteTrack('Site Page View');
})();
