var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DragProgressController } from './drag-controller';
import { Component, ComponentRegistry } from 'libs/components';
import { remap, roundToNearestStep } from 'libs/math';
var RadioSlider = /** @class */ (function (_super) {
    __extends(RadioSlider, _super);
    function RadioSlider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // private radioNumbers: HTMLDivElement[];
        _this.onlyFirstAndLastBubble = false;
        _this.stepSize = 0;
        _this.progress = 0;
        _this.currentIndex = 0;
        return _this;
    }
    RadioSlider.prototype.onInit = function () {
        this.getElements();
        this.determineInitialIndex();
        this.determineStepSize();
        this.determineIfOnlyFirstAndLastBubbleShouldBeDisplayed();
        // this.generateNumbers();
        this.registerListeners();
    };
    RadioSlider.prototype.getElements = function () {
        this.trackElement = this.host.querySelector('.radio-slider__track');
        this.thumbTransformElement = this.host.querySelector('.radio-slider__thumb-transform');
        this.thumbElement = this.host.querySelector('.radio-slider__thumb');
        this.speechBubble = this.host.querySelector('.speech-bubble');
        this.speechBubbleList = this.speechBubble.querySelector('.speech-bubble__list');
        this.speechBubbleTriangle = this.speechBubble.querySelector('.speech-bubble__triangle');
        this.speechBubbleItems = Array.from(this.speechBubble.querySelectorAll('.speech-bubble__item'));
        this.radioInputs = Array.from(this.host.querySelectorAll('.radio-slider-option__input'));
    };
    RadioSlider.prototype.determineIfOnlyFirstAndLastBubbleShouldBeDisplayed = function () {
        if (this.host.hasAttribute('data-only-first-and-last-bubble')) {
            this.onlyFirstAndLastBubble = true;
        }
    };
    RadioSlider.prototype.determineStepSize = function () {
        this.stepSize = 1 / Math.max(this.radioInputs.length - 1, 1);
    };
    RadioSlider.prototype.determineInitialIndex = function () {
        var _this = this;
        var foundCheckedRadio = false;
        this.radioInputs.forEach(function (input, index) {
            if (input.checked) {
                _this.updateCurrentIndex(index);
                foundCheckedRadio = true;
            }
        });
        if (!foundCheckedRadio) {
            this.radioInputs[0].checked = true;
            this.updateCurrentIndex(0);
        }
    };
    RadioSlider.prototype.updateCurrentIndex = function (index) {
        if (this.speechBubbleItems.length > 0) {
            this.speechBubbleItems[this.currentIndex].classList.remove('speech-bubble__item--active');
            this.speechBubbleItems[index].classList.add('speech-bubble__item--active');
        }
        this.currentIndex = index;
    };
    RadioSlider.prototype.registerListeners = function () {
        var _this = this;
        this.dragProgressController = new DragProgressController(this.trackElement);
        this.radioInputs.forEach(function (input) {
            input.addEventListener('change', function () {
                var index = _this.findInputIndex(input);
                var progress = _this.getProgressForIndex(index);
                _this.updateProgress(progress);
            });
        });
        this.dragProgressController.onChange(function (progress) {
            return _this.updateProgress(progress);
        });
        window.addEventListener('resize', function () {
            _this.updateThumbPosition();
        });
    };
    RadioSlider.prototype.findInputIndex = function (input) {
        return this.radioInputs.findIndex(function (i) { return i.value === input.value; });
    };
    RadioSlider.prototype.getProgressForIndex = function (index) {
        return remap(0, this.radioInputs.length - 1, index, 0, 1);
    };
    RadioSlider.prototype.updateProgress = function (progress) {
        var _this = this;
        this.progress = roundToNearestStep(progress, this.stepSize);
        this.selectClosestRadio();
        window.requestAnimationFrame(function () {
            _this.updateThumbPosition();
            if (_this.speechBubbleList) {
                _this.updateSpeechBubblePosition();
            }
        });
    };
    RadioSlider.prototype.updateThumbPosition = function () {
        var thumbX = this.progress * this.trackElement.clientWidth;
        this.thumbTransformElement.style.transform = "translateX(".concat(Math.round(thumbX), "px)");
        if (this.speechBubbleTriangle) {
            this.speechBubbleTriangle.style.transform = "translateX(".concat(Math.round(thumbX), "px)");
        }
    };
    RadioSlider.prototype.updateSpeechBubblePosition = function () {
        var trackWidth = this.trackElement.clientWidth;
        var bubbleWidth = this.speechBubbleList.clientWidth;
        var thumbWidth = this.thumbElement.clientWidth;
        var maxX = trackWidth - bubbleWidth + thumbWidth;
        var x = this.progress * trackWidth;
        x = remap(0, trackWidth, x, 0, maxX);
        if (this.onlyFirstAndLastBubble) {
            this.handleOnlyFirstAndLastBubble();
        }
        this.speechBubbleList.style.transform = "translateX(".concat(Math.round(x), "px)");
    };
    RadioSlider.prototype.handleOnlyFirstAndLastBubble = function () {
        if (this.currentIndex !== 0 &&
            this.currentIndex !== this.speechBubbleItems.length - 1) {
            this.speechBubble.style.opacity = "0";
        }
        else {
            this.speechBubble.style.opacity = "1";
        }
    };
    RadioSlider.prototype.selectClosestRadio = function () {
        var progressIndex = this.getIndexForProgress();
        this.updateCurrentIndex(progressIndex);
        var radioToBeSelected = this.radioInputs[progressIndex];
        radioToBeSelected.checked = true;
    };
    RadioSlider.prototype.getIndexForProgress = function () {
        return roundToNearestStep(remap(0, 1, this.progress, 0, this.radioInputs.length - 1), 1);
    };
    return RadioSlider;
}(Component));
export { RadioSlider };
ComponentRegistry.register('.radio-slider', RadioSlider);
