var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var RadioSubfield = /** @class */ (function (_super) {
    __extends(RadioSubfield, _super);
    function RadioSubfield() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RadioSubfield.prototype.onInit = function () {
        this.getParentChoice();
        this.getFormField();
        this.disableFormField();
        this.registerListeners();
    };
    RadioSubfield.prototype.getParentChoice = function () {
        this.parentChoice = this.getParentComponent('.radio');
    };
    RadioSubfield.prototype.getFormField = function () {
        var _this = this;
        var selectField = this.host.querySelector('.select-field');
        var textField = this.host.querySelector('.input-field__input');
        if (selectField) {
            this.formField = selectField.querySelector('.select-field__button');
            selectField.addEventListener('change', function () {
                _this.parentChoice.check();
            });
        }
        else if (textField) {
            this.formField = textField;
            textField.addEventListener('change', function () {
                _this.parentChoice.check();
            });
        }
    };
    RadioSubfield.prototype.registerListeners = function () {
        var _this = this;
        this.parentChoice.onChecked.subscribe(function () {
            _this.enableFormField();
        });
        this.parentChoice.onUnchecked.subscribe(function () {
            _this.disableFormField();
        });
    };
    RadioSubfield.prototype.enableFormField = function () {
        this.formField.setAttribute('aria-hidden', 'false');
        this.formField.tabIndex = 0;
    };
    RadioSubfield.prototype.disableFormField = function () {
        this.formField.setAttribute('aria-hidden', 'true');
        this.formField.tabIndex = -1;
    };
    return RadioSubfield;
}(Component));
export { RadioSubfield };
ComponentRegistry.register('.radio__subfield', RadioSubfield);
