var ComponentIntersectionObserver = /** @class */ (function () {
    function ComponentIntersectionObserver(init) {
        var _this = this;
        this.observedComponents = [];
        this.observer = new IntersectionObserver(function (entries) { return _this.observerHander(entries); }, init);
    }
    ComponentIntersectionObserver.prototype.observerHander = function (entries) {
        var _this = this;
        entries
            .map(function (entry) { return [
            entry,
            _this.observedComponents.find(function (component) { return entry.target === component.observedContainer; }),
        ]; })
            .forEach(function (_a) {
            var entry = _a[0], component = _a[1];
            return component.onIntersectionStateChange(entry);
        });
    };
    ComponentIntersectionObserver.prototype.observe = function (component) {
        this.observedComponents.push(component);
        this.observer.observe(component.observedContainer);
    };
    ComponentIntersectionObserver.prototype.unobserve = function (component) {
        this.observedComponents.splice(this.observedComponents.findIndex(function (c) { return c === component; }), 1);
        this.observer.unobserve(component.observedContainer);
    };
    return ComponentIntersectionObserver;
}());
export { ComponentIntersectionObserver };
