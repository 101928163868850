// @ts-ignore
// import anime from 'animejs/lib/anime.es.js';
import { ExpandableController } from 'libs/expandable-controller';
var ProfileNavItemSubnavController = /** @class */ (function () {
    function ProfileNavItemSubnavController(id, host) {
        this.id = id;
        this.host = host;
        this.getElements();
        this.setupExpandableController();
    }
    ProfileNavItemSubnavController.prototype.getElements = function () {
        this.link = this.host.querySelector('.profile-nav-item__link');
        this.expandable = this.host.querySelector('.profile-subnav');
        this.list = this.host.querySelector('.profile-subnav__list');
    };
    ProfileNavItemSubnavController.prototype.setupExpandableController = function () {
        var _this = this;
        this.expandableController = new ExpandableController({
            uid: this.id.toString(),
            toggler: this.link,
            expandable: this.expandable,
            expandableContent: this.list,
        });
        this.expandableController.onStateChange.subscribe(function (state) {
            _this.updateHostClass(state.isExpanded);
        });
        this.checkIfSubnavIsInitiallyExpanded();
        this.expandableController.start();
    };
    ProfileNavItemSubnavController.prototype.checkIfSubnavIsInitiallyExpanded = function () {
        if (this.host.querySelector('.profile-subnav-item--active')) {
            this.expandableController.expand(false);
        }
    };
    ProfileNavItemSubnavController.prototype.updateHostClass = function (isExpanded) {
        this.host.classList.toggle('profile-nav-item--expanded', isExpanded);
    };
    return ProfileNavItemSubnavController;
}());
export { ProfileNavItemSubnavController };
