var FocusNavigator = /** @class */ (function () {
    function FocusNavigator(items) {
        this.currentIndex = -1;
        if (items) {
            this.setItems(items);
        }
    }
    FocusNavigator.prototype.setItems = function (items) {
        if (items.length > 0) {
            this.items = items;
        }
    };
    FocusNavigator.prototype.next = function () {
        this.detectFocusedItem();
        this.currentIndex = (this.currentIndex + 1) % this.items.length;
        this.focusCurrentItem();
    };
    FocusNavigator.prototype.previous = function () {
        this.detectFocusedItem();
        var max = this.items.length - 1;
        var newIndex = this.currentIndex - 1;
        if (newIndex < 0) {
            newIndex = max;
        }
        this.currentIndex = newIndex;
        this.focusCurrentItem();
    };
    FocusNavigator.prototype.focusAtIndex = function (index) {
        this.currentIndex = Math.min(Math.max(index, 0), this.items.length - 1);
        this.focusCurrentItem();
    };
    FocusNavigator.prototype.getCurrent = function () {
        return this.items.item(this.currentIndex);
    };
    FocusNavigator.prototype.detectFocusedItem = function () {
        for (var i = 0; i < this.items.length; i++) {
            var item = this.items.item(i);
            if (item === document.activeElement) {
                this.currentIndex = i;
                this.focusCurrentItem();
                break;
            }
        }
    };
    FocusNavigator.prototype.focusCurrentItem = function () {
        this.disableFocusOfItems();
        var currentItem = this.getCurrent();
        currentItem.tabIndex = 0;
        currentItem.focus();
    };
    FocusNavigator.prototype.disableFocusOfItems = function () {
        var _this = this;
        this.items.forEach(function (item) { return _this.disableFocusOfItem(item); });
    };
    FocusNavigator.prototype.disableFocusOfItem = function (item) {
        item.tabIndex = -1;
    };
    return FocusNavigator;
}());
export { FocusNavigator };
