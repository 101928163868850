import { clamp01, invLerp } from 'libs/math';
var DragProgressController = /** @class */ (function () {
    function DragProgressController(host) {
        this.host = host;
        this.isDragging = false;
        this.hostWidth = 0;
        this.hostX = 0;
        this.progress = 0;
        this.registerListeners();
    }
    DragProgressController.prototype.registerListeners = function () {
        var _this = this;
        this.host.addEventListener('mousedown', function (event) {
            return _this.onDragStart(event.clientX);
        });
        this.host.addEventListener('touchstart', function (event) {
            event.preventDefault();
            _this.onDragStart(event.touches[0].clientX);
        });
        window.addEventListener('mousemove', function (event) {
            return _this.onDragMove(event.clientX);
        });
        window.addEventListener('touchmove', function (event) {
            return _this.onDragMove(event.touches[0].clientX);
        });
        window.addEventListener('mouseup', function () { return _this.onDragEnd(); });
        window.addEventListener('touchend', function () { return _this.onDragEnd(); });
    };
    DragProgressController.prototype.onDragStart = function (x) {
        this.isDragging = true;
        this.updateHostX();
        this.updateHostWidth();
        this.calculateProgressFromX(x);
    };
    DragProgressController.prototype.updateHostX = function () {
        this.hostX = this.host.getBoundingClientRect().x;
    };
    DragProgressController.prototype.updateHostWidth = function () {
        this.hostWidth = this.host.clientWidth;
    };
    DragProgressController.prototype.onDragMove = function (x) {
        if (this.isDragging) {
            this.calculateProgressFromX(x);
        }
    };
    DragProgressController.prototype.calculateProgressFromX = function (x) {
        var _a;
        var xRelativeToHost = x - this.hostX;
        var progress = invLerp(0, xRelativeToHost, this.hostWidth);
        progress = clamp01(progress);
        this.progress = progress;
        (_a = this.onChangeCallback) === null || _a === void 0 ? void 0 : _a.call(this, this.progress);
    };
    DragProgressController.prototype.onDragEnd = function () {
        if (this.isDragging) {
            this.isDragging = false;
        }
    };
    DragProgressController.prototype.onChange = function (callback) {
        this.onChangeCallback = callback;
    };
    return DragProgressController;
}());
export { DragProgressController };
