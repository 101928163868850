var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { Subject } from 'rxjs';
var Radio = /** @class */ (function (_super) {
    __extends(Radio, _super);
    function Radio() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onChecked = new Subject();
        _this.onUnchecked = new Subject();
        return _this;
    }
    Object.defineProperty(Radio.prototype, "isChecked", {
        get: function () {
            return this.radioInput.checked;
        },
        enumerable: false,
        configurable: true
    });
    Radio.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    Radio.prototype.onInit = function () {
        var _this = this;
        this.radioInput = this.host.querySelector('.radio__input');
        this.checkmarkElement = this.host.querySelector('.radio__checkmark');
        this.radioInput.addEventListener('change', function () { return _this.check(); });
        this.radioInput.addEventListener('focus', function () { return _this.focus(); });
        if (this.isChecked) {
            this.check();
        }
        this.focusService.onFocusChange.subscribe(function (event) {
            if (_this.radioInput === event.target) {
                _this.focus();
            }
        });
    };
    Radio.prototype.focus = function () {
        if (this.checkmarkElement) {
            this.focusService.showFocusAt(this.checkmarkElement, {
                padding: 3,
            });
        }
    };
    Radio.prototype.check = function () {
        this.radioInput.checked = true;
        this.onChecked.next();
    };
    Radio.prototype.uncheck = function () {
        this.radioInput.checked = false;
        this.onUnchecked.next();
    };
    return Radio;
}(Component));
export { Radio };
ComponentRegistry.register('.radio', Radio);
