export function remap(fromMin, fromMax, value, toMin, toMax) {
    var t = invLerp(fromMin, value, fromMax);
    return lerp(toMin, t, toMax);
}
export function lerp(min, t, max) {
    return (max - min) * t + min;
}
export function invLerp(min, value, max) {
    return (value - min) / (max - min);
}
export function clamp01(value) {
    return clamp(0, value, 1);
}
export function clamp(min, value, max) {
    return Math.max(Math.min(value, max), min);
}
export function roundToNearestStep(value, step, offset) {
    if (offset === void 0) { offset = 0; }
    return Math.round((value - offset) / step) * step + offset;
}
