var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ListJumper } from 'libs/a11y/list-jumper';
import { FocusNavigator } from 'libs/a11y/focus-navigator';
import { Component, ComponentRegistry } from 'libs/components';
import { FocusService } from 'libs/a11y/focus.service';
import { Provider } from 'libs/provider';
var SelectField = /** @class */ (function (_super) {
    __extends(SelectField, _super);
    function SelectField() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.buttonHasFocus = false;
        _this.isOpen = false;
        _this.isSearching = false;
        return _this;
    }
    SelectField.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    SelectField.prototype.onInit = function () {
        var _this = this;
        this.getElements();
        this.setAriaDescribedBy();
        this.focusNavigator = new FocusNavigator(this.options);
        this.listJumper = new ListJumper();
        this.listJumper.setList(this.getLabelsAsStringList());
        this.setUpListeners();
        if (!this.isMultipleSelect) {
            this.options.forEach(function (input) {
                if (input.checked) {
                    _this.onChange({ target: input });
                }
            });
        }
        else {
            this.dropdown.setAttribute('aria-multiselectable', 'true');
            this.onChange({ target: null });
        }
    };
    SelectField.prototype.getElements = function () {
        this.isMultipleSelect = this.host.classList.contains('select-field--multipe');
        this.button = this.host.querySelector('.select-field__button');
        this.dropdown = this.host.querySelector('.select-field__dropdown-inner');
        this.valueDisplay = this.host.querySelector('.select-field__values');
        this.backDrop = this.host.querySelector('.select-field__backdrop');
        this.options = this.host.querySelectorAll('input');
        this.errorElement = this.host.querySelector('.form-field__error');
    };
    SelectField.prototype.setAriaDescribedBy = function () {
        if (this.errorElement) {
            var errorElementId = "error_".concat(this.componentId);
            this.errorElement.setAttribute('id', errorElementId);
            this.button.setAttribute('aria-describedby', errorElementId);
        }
    };
    SelectField.prototype.getLabelsAsStringList = function () {
        var labels = this.host.querySelectorAll('.select-option__label');
        var labelStrings = Array.from(labels).map(function (label) { return label.innerText; });
        return labelStrings;
    };
    SelectField.prototype.setUpListeners = function () {
        var _this = this;
        this.listJumper.onSearchChange.subscribe(function (search) {
            if (search) {
                _this.isSearching = true;
            }
        });
        this.listJumper.onJump.subscribe(function (index) {
            _this.focusNavigator.focusAtIndex(index);
        });
        this.listJumper.onDone.subscribe(function () {
            _this.isSearching = false;
            console.log('done searching');
        });
        this.button.addEventListener('click', function () {
            if (!_this.isOpen) {
                _this.open();
            }
            else {
                _this.close();
            }
        });
        this.host.addEventListener('change', function (event) { return _this.onChange(event); });
        this.host.addEventListener('reset', function () { return _this.onReset(); });
        this.dropdown.addEventListener('scroll', function () {
            if (_this.isOpen) {
                _this.focusService.moveToFocusedElement({ disableAnimation: true });
            }
        });
        this.focusService.onFocusChange.subscribe(function (event) {
            var _a;
            _this.buttonHasFocus = event.target === _this.button;
            if (_this.buttonHasFocus) {
                _this.focusService.showFocusAt(_this.button, {
                    padding: 5,
                });
            }
            if (!((_a = event.target) === null || _a === void 0 ? void 0 : _a.closest('.select-field')) && _this.isOpen) {
                _this.close();
            }
        });
        this.backDrop.addEventListener('click', function (event) {
            event.stopPropagation();
            _this.close();
        });
        window.addEventListener('keydown', function (event) {
            if (_this.isOpen) {
                if (event.code === 'Escape' ||
                    (_this.isMultipleSelect && event.code === 'Enter')) {
                    event.preventDefault();
                    event.stopPropagation();
                    _this.close();
                    _this.button.focus();
                }
                else if (event.code === 'Space' || event.code === 'Enter') {
                    if (!_this.isSearching) {
                        _this.toggleCurrentlyFocused();
                    }
                    else {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                }
                else {
                    if (event.code !== 'Tab') {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    switch (event.code) {
                        case 'ArrowUp':
                        case 'ArrowLeft':
                            _this.focusNavigator.previous();
                            break;
                        case 'ArrowDown':
                        case 'ArrowRight':
                            _this.focusNavigator.next();
                            break;
                    }
                }
            }
            else {
                if ((event.code === 'Space' || event.code === 'Enter') &&
                    _this.buttonHasFocus) {
                    event.preventDefault();
                    event.stopPropagation();
                    _this.open();
                }
            }
        });
    };
    SelectField.prototype.toggleCurrentlyFocused = function () {
        var focusItem = this.focusNavigator.getCurrent();
        if (!this.isMultipleSelect) {
            focusItem.checked = true;
        }
        this.host.dispatchEvent(new Event('change'));
        this.onChange({ target: focusItem });
        if (!this.isMultipleSelect) {
            this.close();
        }
    };
    SelectField.prototype.open = function () {
        var _this = this;
        this.isOpen = true;
        this.hostClass('select-field--open', true);
        this.button.setAttribute('aria-expanded', 'true');
        this.button.tabIndex = -1;
        this.listJumper.activate();
        window.requestAnimationFrame(function () {
            _this.focusSelectedOrFirst();
        });
    };
    SelectField.prototype.focusSelectedOrFirst = function () {
        var hasFocused = false;
        for (var i = 0; i < this.options.length; i++) {
            var option = this.options[i];
            if (option.checked) {
                option.focus();
                hasFocused = true;
                break;
            }
        }
        if (!hasFocused) {
            var firstOption = this.options[0];
            firstOption.focus();
        }
        this.focusNavigator.detectFocusedItem();
    };
    SelectField.prototype.close = function () {
        this.isOpen = false;
        this.button.setAttribute('aria-expanded', 'false');
        this.button.tabIndex = 0;
        this.listJumper.deactivate();
        this.hostClass('select-field--open', false);
    };
    SelectField.prototype.onReset = function () {
        this.options.forEach(function (input) {
            input.checked = false;
        });
        this.valueDisplay.innerHTML = '&nbsp;';
        this.hostClass('select-field--filled', false);
    };
    SelectField.prototype.onChange = function (event) {
        var selectOptions = [];
        var target = event.target;
        if (this.isMultipleSelect) {
            this.options.forEach(function (input) {
                if (input.checked) {
                    selectOptions.push(input.nextElementSibling.nextElementSibling.innerHTML);
                }
            });
        }
        else {
            selectOptions.push(target.nextElementSibling.innerHTML);
            if (!this.focusService.isNavigatingWithKeyboard) {
                this.close();
            }
            this.button.focus();
        }
        var hasOptions = selectOptions.length > 0;
        if (hasOptions) {
            this.valueDisplay.innerHTML = selectOptions.join(', ');
        }
        else {
            this.valueDisplay.innerHTML = '&nbsp;';
        }
        this.hostClass('select-field--filled', hasOptions);
    };
    return SelectField;
}(Component));
ComponentRegistry.register('.select-field', SelectField);
