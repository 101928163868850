var ReducedMotionQuery = /** @class */ (function () {
    function ReducedMotionQuery() {
        this.mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    }
    ReducedMotionQuery.prototype.shouldNotAnimate = function () {
        return this.mediaQuery && this.mediaQuery.matches;
    };
    return ReducedMotionQuery;
}());
export { ReducedMotionQuery };
