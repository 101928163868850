var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var SimpleInput = /** @class */ (function (_super) {
    __extends(SimpleInput, _super);
    function SimpleInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasFocus = false;
        _this.onFocus = function () {
            _this.hasFocus = true;
            _this.update();
        };
        _this.onBlur = function () {
            _this.hasFocus = false;
            _this.update();
        };
        return _this;
    }
    SimpleInput.prototype.onInit = function () {
        this.getElements();
        this.addListeners();
    };
    SimpleInput.prototype.getElements = function () {
        this.input = this.host.querySelector('.simple-input__input');
    };
    SimpleInput.prototype.addListeners = function () {
        this.input.addEventListener('focus', this.onFocus);
        this.input.addEventListener('blur', this.onBlur);
    };
    SimpleInput.prototype.update = function () {
        this.host.classList.toggle('simple-input--focus', this.hasFocus);
    };
    return SimpleInput;
}(Component));
export { SimpleInput };
ComponentRegistry.register('.simple-input', SimpleInput);
