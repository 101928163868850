var TooltipOutlet = /** @class */ (function () {
    function TooltipOutlet(host) {
        this.host = host;
        this.tooltips = new Map();
    }
    TooltipOutlet.prototype.show = function (id, tooltip) {
        var tooltipWrapper = this.wrapTooltip(tooltip);
        this.host.append(tooltipWrapper);
        var wrappedTooltip = {
            tooltip: tooltip,
            wrapper: tooltipWrapper,
        };
        this.tooltips.set(id, wrappedTooltip);
        this.positionTooltip(wrappedTooltip);
    };
    TooltipOutlet.prototype.wrapTooltip = function (tooltip) {
        var tooltipWrapper = document.createElement('div');
        tooltipWrapper.classList.add('tooltip-wrapper');
        tooltipWrapper.append(tooltip.element);
        return tooltipWrapper;
    };
    TooltipOutlet.prototype.positionTooltip = function (wrappedTooltip) {
        var host = wrappedTooltip.tooltip.host;
        var hostRect = host.getBoundingClientRect();
        var hostWidth = hostRect.width;
        var hostHeight = hostRect.height;
        var hostX = hostRect.left + window.scrollX;
        var hostY = hostRect.top + window.scrollY;
        var element = wrappedTooltip.wrapper;
        var elementWidth = element.clientWidth;
        var elementHeight = element.clientHeight;
        var top = 0;
        var left = 0;
        switch (wrappedTooltip.tooltip.position) {
            case 'top':
                top = hostY - elementHeight;
                left = hostX + hostWidth * 0.5 - elementWidth * 0.5;
                break;
            case 'left':
                top = hostY + hostHeight * 0.5 - elementHeight * 0.5;
                left = hostX - elementWidth;
                break;
            case 'right':
                top = hostY + hostHeight * 0.5 - elementHeight * 0.5;
                left = hostX + hostWidth;
                break;
            case 'bottom':
                top = hostY + hostHeight;
                left = hostX + hostWidth * 0.5 - elementWidth * 0.5;
                break;
        }
        element.style.top = "".concat(top, "px");
        element.style.left = "".concat(left, "px");
    };
    TooltipOutlet.prototype.hide = function (id) {
        var wrappedModal = this.tooltips.get(id);
        this.host.removeChild(wrappedModal.wrapper);
        this.tooltips.delete(id);
    };
    return TooltipOutlet;
}());
export { TooltipOutlet };
