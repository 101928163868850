var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var RadioBox = /** @class */ (function (_super) {
    __extends(RadioBox, _super);
    function RadioBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.SELECTED_CLASS = 'radio-box--selected';
        _this.textInput = null;
        return _this;
    }
    Object.defineProperty(RadioBox.prototype, "isChecked", {
        get: function () {
            return this.radioInput.checked;
        },
        enumerable: false,
        configurable: true
    });
    RadioBox.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    RadioBox.prototype.onInit = function () {
        this.getElements();
        if (this.textInput) {
            this.disableSubfield();
        }
        if (this.radioInput.checked) {
            this.check();
        }
        this.registerListeners();
        this.registerA11yListeners();
    };
    RadioBox.prototype.getElements = function () {
        this.radioInput = this.host.querySelector('.radio__input');
        this.textInput = this.host.querySelector('.input-field__input');
        this.subfieldWrapper = this.host.querySelector('.radio-box__subfield');
    };
    RadioBox.prototype.registerListeners = function () {
        var _this = this;
        var _a;
        (_a = this.textInput) === null || _a === void 0 ? void 0 : _a.addEventListener('input', function () { return _this.onTextInputChange(); });
        this.host.addEventListener('click', function () {
            _this.check();
        });
    };
    RadioBox.prototype.onTextInputChange = function () {
        var _a;
        if ((((_a = this.textInput) === null || _a === void 0 ? void 0 : _a.value.length) || 0) > 0) {
            this.check();
        }
    };
    RadioBox.prototype.registerA11yListeners = function () {
        var _this = this;
        this.radioInput.addEventListener('focus', function () { return _this.focus(); });
        this.focusService.onFocusChange.subscribe(function (event) {
            if (event.target === _this.radioInput) {
                _this.focus();
            }
        });
    };
    RadioBox.prototype.focus = function () {
        this.focusService.showFocusAt(this.host);
    };
    RadioBox.prototype.check = function () {
        this.hostClass(this.SELECTED_CLASS, true);
        this.radioInput.checked = true;
        if (this.textInput) {
            this.enableSubfield();
        }
        this.host.dispatchEvent(new CustomEvent('radiochange', {
            bubbles: true,
            detail: {
                target: this,
            },
        }));
    };
    RadioBox.prototype.uncheck = function () {
        this.hostClass(this.SELECTED_CLASS, false);
        this.radioInput.checked = false;
        if (this.textInput) {
            this.disableSubfield();
        }
    };
    RadioBox.prototype.enableSubfield = function () {
        this.textInput.tabIndex = 0;
        this.textInput.setAttribute('aria-disabled', 'false');
        this.subfieldWrapper.setAttribute('aria-hidden', 'false');
    };
    RadioBox.prototype.disableSubfield = function () {
        this.textInput.tabIndex = -1;
        this.textInput.setAttribute('aria-disabled', 'true');
        this.subfieldWrapper.setAttribute('aria-hidden', 'true');
    };
    return RadioBox;
}(Component));
export { RadioBox };
ComponentRegistry.register('.radio-box', RadioBox);
