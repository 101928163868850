var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { FocusService } from 'libs/a11y/focus.service';
import { Provider } from 'libs/provider';
var FileField = /** @class */ (function (_super) {
    __extends(FileField, _super);
    function FileField() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._hasFile = false;
        return _this;
    }
    Object.defineProperty(FileField.prototype, "hasFile", {
        set: function (hasFile) {
            this._hasFile = hasFile;
            this.hostClass('file-field--has-file', hasFile);
        },
        enumerable: false,
        configurable: true
    });
    FileField.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    FileField.prototype.onInit = function () {
        this.inputElement = this.host.querySelector('.file-field__input');
        this.labelElement = this.host.querySelector('.file-field__label');
        this.labelValue = this.labelElement.innerHTML;
        this.setUpListeners();
        this.setUpA11yListeners();
    };
    FileField.prototype.setUpListeners = function () {
        var _this = this;
        this.inputElement.addEventListener('change', function (event) {
            return _this.onChange(event);
        });
        this.labelElement.addEventListener('dragover', this.onDragOver.bind(this), false);
        window.addEventListener('drop', this.onDrop.bind(this), false);
        window.addEventListener('dragleave', this.onDragLeave.bind(this), false);
    };
    FileField.prototype.setUpA11yListeners = function () {
        var _this = this;
        this.inputElement.addEventListener('focus', function () { return _this.focus(); });
        this.focusService.onFocusChange.subscribe(function (event) {
            if (event.target === _this.inputElement) {
                _this.focus();
            }
        });
    };
    FileField.prototype.focus = function () {
        this.focusService.showFocusAt(this.host);
    };
    FileField.prototype.onDrop = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.inputElement.files = event.dataTransfer.files;
        this.inputElement.dispatchEvent(new Event('change'));
        this.hostClass('file-field--dragover', false);
    };
    FileField.prototype.onDragOver = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.hostClass('file-field--dragover', true);
    };
    FileField.prototype.onDragLeave = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.hostClass('file-field--dragover', false);
    };
    FileField.prototype.onDelete = function () {
        var _this = this;
        this.inputElement.value = '';
        this.host
            .querySelectorAll('.file-field__file')
            .forEach(function (e) { return e.parentNode.removeChild(e); });
        this.hasFile = false;
        window.requestAnimationFrame(function () {
            return _this.focusService.focus(_this.inputElement);
        });
    };
    FileField.prototype.onChange = function (event) {
        var _this = this;
        var target = event.target;
        var fileName = '';
        if (this.inputElement.files && this.inputElement.files.length > 1) {
            fileName = (this.inputElement.getAttribute('data-multiple-caption') ||
                '{count} Files').replace('{count}', String(this.inputElement.files.length));
        }
        else {
            fileName = target.value.split('\\').pop();
        }
        var fileSizeKb = this.inputElement.files[0].size / 1024;
        var fileElement = this.getFileHtml(fileName, fileSizeKb);
        this.host
            .querySelectorAll('.file-field__file')
            .forEach(function (e) { return e.parentNode.removeChild(e); });
        this.host.appendChild(fileElement);
        this.hasFile = true;
        fileElement.addEventListener('click', function () { return _this.onDelete(); });
    };
    FileField.prototype.getFileHtml = function (name, fileSizeKb) {
        var fileHtml = document.createElement('div');
        fileHtml.classList.add('file-field__file');
        var template = document.getElementById('file-template');
        var templateFragment = document.importNode(template.content, true);
        var nameElement = templateFragment.querySelector('.file-field__file__name');
        nameElement.innerHTML = name;
        var sizeElement = templateFragment.querySelector('.file-field__file__size');
        sizeElement.innerHTML = this.formatFileSize(fileSizeKb);
        fileHtml.appendChild(templateFragment);
        return fileHtml;
    };
    FileField.prototype.formatFileSize = function (fileSizeKb) {
        var fileSize;
        if (fileSizeKb > 1024) {
            fileSize = "".concat((fileSizeKb / 1024).toFixed(2), " MB");
        }
        else {
            fileSize = "".concat(fileSizeKb.toFixed(2), " KB");
        }
        return fileSize;
    };
    return FileField;
}(Component));
ComponentRegistry.register('.file-field', FileField);
