var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MobileNavBehavior } from './mobile-nav-behavior';
import { DesktopNavBehavior } from './desktop-nav-behavior';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { FocusService } from 'libs/a11y';
var MainNav = /** @class */ (function (_super) {
    __extends(MainNav, _super);
    function MainNav() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.breakpoint = 992;
        _this.previousWidth = window.innerWidth;
        return _this;
    }
    MainNav.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    MainNav.prototype.onInit = function () {
        this.mobileBehavior = new MobileNavBehavior(this.host, this.focusService);
        this.desktopBehavior = new DesktopNavBehavior(this.host, this.focusService);
        this.setupResizeListener();
        this.activateInitialBehavior();
    };
    MainNav.prototype.activateInitialBehavior = function () {
        var initialBehavior = window.innerWidth < this.breakpoint
            ? this.mobileBehavior
            : this.desktopBehavior;
        this.activateBehavior(initialBehavior);
        this.previousWidth = window.innerWidth;
    };
    MainNav.prototype.setupResizeListener = function () {
        var _this = this;
        window.addEventListener('resize', function () {
            if (window.innerWidth < _this.breakpoint &&
                _this.previousWidth >= _this.breakpoint) {
                _this.activateBehavior(_this.mobileBehavior);
            }
            else if (window.innerWidth >= _this.breakpoint &&
                _this.previousWidth < _this.breakpoint) {
                _this.activateBehavior(_this.desktopBehavior);
            }
            _this.previousWidth = window.innerWidth;
        });
    };
    MainNav.prototype.activateBehavior = function (behavior) {
        var _a;
        (_a = this.currentBehavior) === null || _a === void 0 ? void 0 : _a.deactivate();
        this.currentBehavior = behavior;
        this.currentBehavior.activate();
    };
    return MainNav;
}(Component));
ComponentRegistry.register('.main-nav', MainNav);
