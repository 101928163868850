var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { ExpandableController } from 'libs/expandable-controller';
var ListFilters = /** @class */ (function (_super) {
    __extends(ListFilters, _super);
    function ListFilters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListFilters.prototype.onInit = function () {
        this.getElements();
        this.addListeners();
        this.setupExpandables();
    };
    ListFilters.prototype.getElements = function () {
        this.filterToggle = this.host.querySelector('.filter-button');
        this.filterExpandable = this.host.querySelector('.list-filters__expandable');
        this.filterExpandableInner = this.filterExpandable.querySelector('.list-filters__filters');
        this.activeFiltersExpandable = this.host.querySelector('.active-filters');
        this.activeFiltersExpandableInner =
            this.activeFiltersExpandable.querySelector('.active-filters__inner');
        this.searchInput = this.host.querySelector('#list-search-field');
        this.form = this.host.closest('form');
    };
    ListFilters.prototype.addListeners = function () {
        var _this = this;
        var _a;
        (_a = this.searchInput) === null || _a === void 0 ? void 0 : _a.addEventListener('keydown', function (event) {
            switch (event.code) {
                case 'Enter':
                    event.preventDefault();
                    event.stopPropagation();
                    _this.form.submit();
                    break;
            }
        });
    };
    ListFilters.prototype.setupExpandables = function () {
        var _this = this;
        this.filtersExpandableController = new ExpandableController({
            uid: this.componentId.toString(),
            toggler: this.filterToggle,
            expandable: this.filterExpandable,
            expandableContent: this.filterExpandableInner,
        });
        this.activeFiltersExpandableController = new ExpandableController({
            uid: this.componentId.toString(),
            expandable: this.activeFiltersExpandable,
            expandableContent: this.activeFiltersExpandableInner,
        });
        this.filtersExpandableController.onStateChange.subscribe(function (state) {
            _this.updateStyles(state.isExpanded);
            if (state.isExpanded) {
                _this.activeFiltersExpandableController.collapse();
            }
            else {
                _this.activeFiltersExpandableController.expand();
            }
        });
        this.activeFiltersExpandableController.expand(false);
        this.filtersExpandableController.start();
        this.activeFiltersExpandableController.start();
    };
    ListFilters.prototype.updateStyles = function (isExpanded) {
        this.filterToggle.classList.toggle('btn--filled', isExpanded);
        this.filterToggle.classList.toggle('btn--outline', !isExpanded);
    };
    return ListFilters;
}(Component));
export { ListFilters };
ComponentRegistry.register('.list-filters', ListFilters);
