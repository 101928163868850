import { TooltipRef } from './tooltip-ref';
var TooltipService = /** @class */ (function () {
    function TooltipService(outlet) {
        this.outlet = outlet;
        this.id = -1;
    }
    TooltipService.prototype.show = function (tooltip) {
        var id = ++this.id;
        var ref = new TooltipRef(this.outlet, id);
        this.outlet.show(id, tooltip);
        return ref;
    };
    return TooltipService;
}());
export { TooltipService };
