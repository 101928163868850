import { BehaviorSubject } from 'rxjs';
var YoutubeIframeApiLoader = /** @class */ (function () {
    function YoutubeIframeApiLoader() {
        this._isReady$ = new BehaviorSubject(false);
    }
    YoutubeIframeApiLoader.prototype.load = function () {
        var _this = this;
        var tag = document.createElement('script');
        window['onYouTubeIframeAPIReady'] = function () {
            _this._isReady$.next(true);
        };
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };
    Object.defineProperty(YoutubeIframeApiLoader.prototype, "isReady", {
        get: function () {
            return this._isReady$.asObservable();
        },
        enumerable: false,
        configurable: true
    });
    return YoutubeIframeApiLoader;
}());
export { YoutubeIframeApiLoader };
