var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var SuccessStoryList = /** @class */ (function (_super) {
    __extends(SuccessStoryList, _super);
    function SuccessStoryList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SuccessStoryList.prototype.onInit = function () {
        var _this = this;
        this.targetSelector = this.host.getAttribute('data-ajax-target');
        this.targetEl = document.querySelector(this.targetSelector);
        this.host.addEventListener('click', function (event) {
            event.preventDefault();
            _this.loadMore(event.target.getAttribute('href'));
        });
    };
    SuccessStoryList.prototype.loadMore = function (url) {
        var _this = this;
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                var doc = new DOMParser().parseFromString(xhr.responseText, 'text/html');
                var contents = doc.querySelectorAll(_this.targetSelector + ' .success_story_list__item');
                for (var idx = 0; idx < contents.length; idx++) {
                    _this.targetEl.appendChild(contents[idx]);
                }
                var nextPageUrl = doc
                    .querySelector(_this.targetSelector)
                    .getAttribute('data-next-page-url');
                if (nextPageUrl) {
                    _this.host.setAttribute('href', nextPageUrl);
                }
                else {
                    _this.host.parentNode.removeChild(_this.host);
                }
            }
        };
        xhr.open('GET', url);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send();
    };
    return SuccessStoryList;
}(Component));
ComponentRegistry.register('[data-ajax-load-more]', SuccessStoryList);
