import { Subject } from 'rxjs';
var AutoCompleteModel = /** @class */ (function () {
    function AutoCompleteModel() {
        this.options = [];
        this.value = '';
        this.selected = null;
        this.onStateChange$ = new Subject();
    }
    AutoCompleteModel.prototype.getValue = function () {
        return this.value;
    };
    AutoCompleteModel.prototype.setValue = function (value) {
        this.value = value;
        this.emitChange('value');
    };
    AutoCompleteModel.prototype.getOptions = function () {
        return this.options;
    };
    AutoCompleteModel.prototype.setOptions = function (options) {
        this.options = options;
        this.emitChange('options');
    };
    AutoCompleteModel.prototype.getSelected = function () {
        return this.selected;
    };
    AutoCompleteModel.prototype.setSelected = function (selected) {
        this.selected = selected;
        this.emitChange('selected');
    };
    AutoCompleteModel.prototype.emitChange = function (name) {
        this.onStateChange$.next({
            name: name,
        });
    };
    AutoCompleteModel.prototype.onStateChange = function () {
        return this.onStateChange$.asObservable();
    };
    return AutoCompleteModel;
}());
export { AutoCompleteModel };
