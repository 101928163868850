var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var CategoryChoice = /** @class */ (function (_super) {
    __extends(CategoryChoice, _super);
    function CategoryChoice() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isInfoOpen = false;
        return _this;
    }
    CategoryChoice.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    CategoryChoice.prototype.onInit = function () {
        this.input = this.host.querySelector('.category-choice__input');
        this.visual = this.host.querySelector('.category-choice__visual');
        this.infoToggle = this.host.querySelector('.category-choice__info-toggle');
        this.setA11yListeners();
        this.setUpListeners();
    };
    CategoryChoice.prototype.setA11yListeners = function () {
        var _this = this;
        this.input.addEventListener('focus', function () { return _this.focus(); });
        this.input.addEventListener('blur', function () { return _this.blur(); });
        this.focusService.onFocusChange.subscribe(function (event) {
            if (event.target === _this.input) {
                _this.focus();
            }
            else {
                _this.blur();
            }
        });
    };
    CategoryChoice.prototype.setUpListeners = function () {
        var _this = this;
        this.infoToggle.addEventListener('click', function (event) {
            event.preventDefault();
            _this.toggleInfo();
        });
    };
    CategoryChoice.prototype.toggleInfo = function () {
        if (this.isInfoOpen) {
            this.closeInfo();
        }
        else {
            this.openInfo();
        }
    };
    CategoryChoice.prototype.openInfo = function () {
        this.isInfoOpen = true;
        this.infoToggle.setAttribute('aria-expanded', 'true');
        this.hostClass('category-choice--info-open', true);
    };
    CategoryChoice.prototype.closeInfo = function () {
        this.isInfoOpen = false;
        this.infoToggle.setAttribute('aria-expanded', 'false');
        this.hostClass('category-choice--info-open', false);
    };
    CategoryChoice.prototype.focus = function () {
        this.hostClass('category-choice--focus', true);
        this.focusService.showFocusAt(this.visual);
    };
    CategoryChoice.prototype.blur = function () {
        this.hostClass('category-choice--focus', false);
    };
    return CategoryChoice;
}(Component));
ComponentRegistry.register('.category-choice-field .category-choice', CategoryChoice);
