var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var RadioButtonGroup = /** @class */ (function (_super) {
    __extends(RadioButtonGroup, _super);
    function RadioButtonGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RadioButtonGroup.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    RadioButtonGroup.prototype.onInit = function () {
        this.getElements();
        this.watchChanges();
        this.selectInitial();
    };
    RadioButtonGroup.prototype.getElements = function () {
        this.radios = Array.from(this.host.querySelectorAll('.radio-button-group__radio'));
    };
    RadioButtonGroup.prototype.watchChanges = function () {
        var _this = this;
        var _loop_1 = function (radio) {
            var input = radio.querySelector('input');
            input.addEventListener('input', function () {
                _this.select(radio);
            });
            this_1.focusService.onFocusChange.subscribe(function () {
                if (document.activeElement === input) {
                    _this.focusService.showFocusAt(radio);
                }
            });
        };
        var this_1 = this;
        for (var _i = 0, _a = this.radios; _i < _a.length; _i++) {
            var radio = _a[_i];
            _loop_1(radio);
        }
    };
    RadioButtonGroup.prototype.selectInitial = function () {
        var initiallyChecked = this.host.querySelector('[checked]');
        if (initiallyChecked) {
            var radio = initiallyChecked.closest('.radio-button-group__radio');
            this.select(radio);
        }
    };
    RadioButtonGroup.prototype.select = function (radio) {
        this.clearSelectedStyles();
        radio.classList.add('radio-button-group__radio--selected');
    };
    RadioButtonGroup.prototype.clearSelectedStyles = function () {
        for (var _i = 0, _a = this.radios; _i < _a.length; _i++) {
            var radio = _a[_i];
            radio.classList.remove('radio-button-group__radio--selected');
        }
    };
    return RadioButtonGroup;
}(Component));
ComponentRegistry.register('.radio-button-group', RadioButtonGroup);
