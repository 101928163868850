var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { ExpandableController } from 'libs/expandable-controller';
var ListFiltersToolbar = /** @class */ (function (_super) {
    __extends(ListFiltersToolbar, _super);
    function ListFiltersToolbar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListFiltersToolbar.prototype.onInit = function () {
        this.setupSearchFieldExpandable();
    };
    ListFiltersToolbar.prototype.setupSearchFieldExpandable = function () {
        var toggler = this.host.querySelector('.list-filters-toolbar__button--search');
        var expandable = this.host.querySelector('.list-filters-toolbar__search-field');
        var expandableContent = expandable.children[0];
        if (!toggler) {
            return;
        }
        this.searchFieldExpandable = new ExpandableController({
            uid: this.componentId.toString(),
            toggler: toggler,
            expandable: expandable,
            expandableContent: expandableContent,
        });
        this.searchFieldExpandable.start();
    };
    return ListFiltersToolbar;
}(Component));
export { ListFiltersToolbar };
ComponentRegistry.register('.list-filters-toolbar', ListFiltersToolbar);
