var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var DependingField = /** @class */ (function (_super) {
    __extends(DependingField, _super);
    function DependingField() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DependingField.prototype.onInit = function () {
        var _this = this;
        this.display = this.host.style.display;
        var dependendFieldName = this.host.getAttribute('data-depending-field');
        this.dependendFieldValue = this.host.getAttribute('data-depending-value');
        var dependendFields = document.querySelectorAll("input[name=\"".concat(dependendFieldName, "\"]"));
        this.isNotMatching();
        var _loop_1 = function (idx) {
            var field = dependendFields[idx];
            this_1.checkForMatch(field, false);
            field.addEventListener('change', function () { return _this.checkForMatch(field); });
        };
        var this_1 = this;
        for (var idx = 0; idx < dependendFields.length; idx++) {
            _loop_1(idx);
        }
    };
    DependingField.prototype.checkForMatch = function (field, callIfNotMatch) {
        if (callIfNotMatch === void 0) { callIfNotMatch = true; }
        switch (field.type) {
            case 'radio':
                if (field.checked && field.value === this.dependendFieldValue) {
                    this.isMatching();
                }
                else if (callIfNotMatch) {
                    this.isNotMatching();
                }
                break;
        }
    };
    DependingField.prototype.isMatching = function () {
        this.host.style.display = this.display;
    };
    DependingField.prototype.isNotMatching = function () {
        this.host.style.display = 'none';
    };
    return DependingField;
}(Component));
ComponentRegistry.register('[data-depending-field]', DependingField);
