var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Overlay } from '../overlay';
import { Component, ComponentRegistry } from 'libs/components';
import { FocusService } from 'libs/a11y';
import { Provider } from 'libs/provider';
var Flyout = /** @class */ (function (_super) {
    __extends(Flyout, _super);
    function Flyout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isOpen = false;
        _this.buttonHasFocus = false;
        return _this;
    }
    Flyout.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
        this.overlay = Provider.get(Overlay);
    };
    Flyout.prototype.onInit = function () {
        var _this = this;
        this.iconButton = this.host.querySelector('.flyout__button');
        this.contentElement = this.host.querySelector('.flyout__content');
        this.iconButton.tabIndex = 0;
        this.iconButton.setAttribute('aria-expanded', 'false');
        this.iconButton.setAttribute('aria-controls', this.contentElement.id);
        this.contentElement.tabIndex = -1;
        this.iconButton.addEventListener('touchstart', function (event) {
            _this.buttonHasFocus = true;
        });
        this.iconButton.addEventListener('click', function (event) {
            if (_this.buttonHasFocus) {
                event.preventDefault();
                event.stopPropagation();
                _this.toggle();
            }
        });
        this.host.addEventListener('keydown', function (event) {
            if (event.code === 'Escape') {
                _this.close();
                _this.iconButton.focus();
            }
        });
        this.contentElement.addEventListener('blur', function () {
            setTimeout(function () { return _this.close(); }, 200);
        });
        this.focusService.onFocusChange.subscribe(function (event) {
            if (event.target === _this.iconButton) {
                _this.focus();
            }
            else {
                _this.buttonHasFocus = false;
            }
        });
    };
    Flyout.prototype.toggle = function () {
        if (this.isOpen) {
            this.close();
        }
        else {
            this.open();
        }
    };
    Flyout.prototype.open = function () {
        var _a;
        this.hostClass('flyout--open', true);
        this.iconButton.setAttribute('aria-expanded', 'true');
        this.contentElement.tabIndex = 0;
        this.contentElement.focus();
        (_a = this.overlay.focusTrap) === null || _a === void 0 ? void 0 : _a.pause();
        this.isOpen = true;
    };
    Flyout.prototype.close = function () {
        var _a;
        this.hostClass('flyout--open', false);
        this.iconButton.setAttribute('aria-expanded', 'false');
        this.contentElement.tabIndex = -1;
        (_a = this.overlay.focusTrap) === null || _a === void 0 ? void 0 : _a.unpause();
        this.isOpen = false;
    };
    Flyout.prototype.focus = function () {
        this.focusService.showFocusAt(this.iconButton, {
            padding: 3,
        });
        this.buttonHasFocus = true;
    };
    return Flyout;
}(Component));
ComponentRegistry.register('.flyout', Flyout);
