var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var RadioBoxes = /** @class */ (function (_super) {
    __extends(RadioBoxes, _super);
    function RadioBoxes() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RadioBoxes.prototype.onInit = function () {
        var _this = this;
        // radiochange is a custom event emitted by radio-box-components
        this.host.addEventListener('radiochange', (function (event) {
            var _a;
            if (_this.currentChoice !== event.detail.target) {
                (_a = _this.currentChoice) === null || _a === void 0 ? void 0 : _a.uncheck();
                _this.currentChoice = event.detail.target;
            }
        }));
    };
    return RadioBoxes;
}(Component));
ComponentRegistry.register('.radio-boxes', RadioBoxes);
