var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var SelectOptionRadio = /** @class */ (function (_super) {
    __extends(SelectOptionRadio, _super);
    function SelectOptionRadio() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.CHECKED_CLASS = 'select-option--checked';
        return _this;
    }
    SelectOptionRadio.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    SelectOptionRadio.prototype.onInit = function () {
        var _this = this;
        this.input = this.host.querySelector('.select-option__input');
        this.input.addEventListener('focus', this.focus.bind(this));
        this.input.addEventListener('blur', this.blur.bind(this));
        this.focusService.onFocusChange.subscribe(function (event) {
            if (event.target === _this.input) {
                _this.focus();
            }
            else {
                _this.blur();
            }
        });
    };
    SelectOptionRadio.prototype.select = function () {
        this.input.checked = true;
        this.hostClass(this.CHECKED_CLASS, true);
    };
    SelectOptionRadio.prototype.deselect = function () {
        this.input.checked = false;
        this.hostClass(this.CHECKED_CLASS, false);
    };
    SelectOptionRadio.prototype.focus = function () {
        this.focusService.showFocusAt(this.host, {
            padding: 1,
        });
        this.hostClass('select-option--has-focus', true);
    };
    SelectOptionRadio.prototype.blur = function () {
        this.hostClass('select-option--has-focus', false);
    };
    return SelectOptionRadio;
}(Component));
export { SelectOptionRadio };
ComponentRegistry.register('.select-option--radio', SelectOptionRadio);
