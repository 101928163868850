import { ComponentRegistry } from './component-registry';
export function getParentComponent(child, selector) {
    var parentElement = child.closest(selector);
    if (!parentElement) {
        throw new Error("Can't find parent with selector '".concat(selector, "' for child '.").concat(child.className
            .split(' ')
            .join('.'), "'"));
    }
    return getComponentForElement(parentElement);
}
export function getChildComponent(parent, selector) {
    var hostElement = parent.querySelector(selector);
    if (!hostElement) {
        throw new Error("Can't find selector '".concat(selector, "' in parent '.").concat(parent.className
            .split(' ')
            .join('.'), "'"));
    }
    return getComponentForElement(hostElement);
}
export function getChildComponents(parent, selector) {
    var components = [];
    var hostElements = Array.from(parent.querySelectorAll(selector));
    for (var _i = 0, hostElements_1 = hostElements; _i < hostElements_1.length; _i++) {
        var hostElement = hostElements_1[_i];
        var component = getComponentForElement(hostElement);
        if (component) {
            components.push(component);
        }
    }
    return components;
}
export function getComponentForElement(hostElement) {
    var id = parseInt(hostElement.dataset['componentId'] || '');
    return ComponentRegistry.get(id);
}
