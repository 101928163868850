"use strict";
var mobile_nav = document.querySelectorAll('[name=mobile_profile_nav]');
var _loop_1 = function (idx) {
    mobile_nav[idx].addEventListener('change', function () {
        location.href = mobile_nav[idx].value;
    });
};
for (var idx = 0; idx < mobile_nav.length; idx++) {
    _loop_1(idx);
}
