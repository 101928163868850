var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @ts-ignore
import anime from 'animejs/lib/anime.es.js';
import { FocusScroller } from './focus-scroller';
import { EasingFunctions } from 'libs/easing';
import { BehaviorSubject, Subject } from 'rxjs';
export var defaultFocusDisplayOptions = {
    disableAnimation: false,
    padding: 10,
};
var FocusService = /** @class */ (function () {
    function FocusService(reducedMotionQuery) {
        var _this = this;
        this.reducedMotionQuery = reducedMotionQuery;
        this.isVisible = false;
        this.alreadyMovedToElement = false;
        this.onStartKeyboardNavigation = new Subject();
        this.onFocusChange = new BehaviorSubject({
            previousTarget: null,
            target: null,
        });
        this.onEndKeyboardNavigation = new Subject();
        this.createFocusIndicatorLayer();
        this.createFocusIndicator();
        this.focusScroller = new FocusScroller(window, { top: 200, bottom: 100 });
        this.focusScroller.exclude('.header', '.main-nav', '.language-switch', '.skip-links');
        window.addEventListener('keyup', function (event) {
            if (event.code === 'Tab') {
                _this.onTab();
            }
        });
        document.addEventListener('focusin', function () {
            _this.updateFocusedTarget(document.activeElement);
        });
        window.addEventListener('focus', function () { return _this.startKeyboardNavigation(); });
        window.addEventListener('blur', function () { return _this.stopKeyboardNavigation(); });
        window.addEventListener('mousedown', this.stopKeyboardNavigation.bind(this));
        window.addEventListener('resize', this.moveToFocusedElement.bind(this, { disableAnimation: true }));
        window.addEventListener('scroll', this.moveToFocusedElement.bind(this, { disableAnimation: true }));
    }
    Object.defineProperty(FocusService.prototype, "isNavigatingWithKeyboard", {
        get: function () {
            return this.isVisible;
        },
        enumerable: false,
        configurable: true
    });
    FocusService.prototype.createFocusIndicatorLayer = function () {
        this.layer = document.createElement('div');
        this.layer.classList.add('focus-layer');
        document.body.appendChild(this.layer);
    };
    FocusService.prototype.createFocusIndicator = function () {
        this.indicator = document.createElement('div');
        this.indicator.classList.add('focus-indicator');
        this.layer.appendChild(this.indicator);
    };
    FocusService.prototype.onTab = function () {
        if (document.activeElement) {
            this.startKeyboardNavigation();
        }
        else {
            this.stopKeyboardNavigation();
        }
    };
    FocusService.prototype.updateFocusedTarget = function (element) {
        this.previousTarget = this.currentTarget;
        this.currentTarget = element;
        this.alreadyMovedToElement = false;
        if (this.isNavigatingWithKeyboard) {
            this.focusScroller.scrollTo(this.currentTarget);
        }
        this.onFocusChange.next({
            previousTarget: this.previousTarget,
            target: this.currentTarget,
        });
        if (!this.alreadyMovedToElement) {
            this.moveToFocusedElement();
        }
    };
    FocusService.prototype.startKeyboardNavigation = function () {
        if (!this.isVisible) {
            document.body.classList.add('navigating-with-keyboard');
            this.layer.classList.add('focus-layer--visible');
            this.isVisible = true;
            this.onStartKeyboardNavigation.next();
        }
    };
    FocusService.prototype.stopKeyboardNavigation = function () {
        if (this.isVisible) {
            document.body.classList.remove('navigating-with-keyboard');
            this.layer.classList.remove('focus-layer--visible');
            this.isVisible = false;
            this.onEndKeyboardNavigation.next();
        }
    };
    FocusService.prototype.focus = function (element) {
        element.focus();
        this.updateFocusedTarget(element);
    };
    FocusService.prototype.moveToFocusedElement = function (displayOptions) {
        if (!this.currentTarget) {
            return;
        }
        this.animateToElement(this.currentTarget, displayOptions);
        this.alreadyMovedToElement = true;
    };
    FocusService.prototype.showFocusAt = function (element, displayOptions) {
        this.animateToElement(element, displayOptions);
        this.alreadyMovedToElement = true;
    };
    FocusService.prototype.animateToElement = function (element, displayOptions) {
        var options = __assign(__assign({}, defaultFocusDisplayOptions), displayOptions);
        var clientRect = element.getBoundingClientRect();
        var padding = options.padding;
        var width = clientRect.width + padding * 2;
        var height = clientRect.height + padding * 2;
        var x = document.documentElement.scrollLeft + clientRect.x - padding;
        var y = document.documentElement.scrollTop + clientRect.y - padding;
        var animation = anime({
            targets: this.indicator,
            width: width,
            height: height,
            translateX: x,
            translateY: y,
            duration: 300,
            autoplay: false,
            easing: function () { return EasingFunctions.easeOutCubic; },
        });
        if (options.disableAnimation ||
            this.reducedMotionQuery.shouldNotAnimate()) {
            animation.seek(animation.duration);
        }
        else {
            animation.play();
        }
    };
    return FocusService;
}());
export { FocusService };
