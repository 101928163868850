var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import * as Hammer from 'hammerjs';
var ProjectListMobile = /** @class */ (function (_super) {
    __extends(ProjectListMobile, _super);
    function ProjectListMobile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectListMobile.prototype.onInit = function () {
        this.swipeContainer = this.host.querySelector('.swipe_container');
        this.hammerEl = new Hammer(this.swipeContainer);
        this.active = parseInt(this.swipeContainer.getAttribute('data-active'), 0);
        this.total = this.host.querySelectorAll('.project_list__item').length;
        this.swipeContainer.setAttribute('data-active', (this.active % this.total).toString());
        this.dots = this.host.querySelectorAll('.dot');
        if (this.dots[this.active]) {
            this.dots[this.active].classList.add('dot--active');
        }
        this.setUpListeners();
    };
    ProjectListMobile.prototype.setUpListeners = function () {
        var _this = this;
        this.hammerEl.on('swiperight', function () {
            if (_this.active > 0) {
                _this.active--;
                _this.setActive();
            }
        });
        this.hammerEl.on('swipeleft', function () {
            if (_this.active < _this.total - 1) {
                _this.active++;
                _this.setActive();
            }
        });
        var _loop_1 = function (i) {
            this_1.dots[i].addEventListener('click', function (evt) {
                evt.preventDefault;
                _this.active = i;
                _this.setActive();
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.dots.length; i++) {
            _loop_1(i);
        }
    };
    ProjectListMobile.prototype.setActive = function () {
        this.swipeContainer.setAttribute('data-active', (this.active % this.total).toString());
        for (var i = 0; i < this.dots.length; i++) {
            this.dots[i].classList.remove('dot--active');
        }
        this.dots[this.active].classList.add('dot--active');
    };
    return ProjectListMobile;
}(Component));
ComponentRegistry.register('.swipe_wrapper', ProjectListMobile);
