// @ts-ignore
import anime from 'animejs/lib/anime.es.js';
var slideIn = anime({
    targets: '.home-intro__title',
    opacity: [0, 1],
    translateY: {
        value: [20, 0],
        easing: 'easeInOutSine',
    },
    duration: 1000,
    easing: 'easeInOutQuad',
    autoplay: false,
    delay: 0,
});
var slideInText = anime({
    targets: '.home-intro__content',
    opacity: [0, 1],
    translateY: {
        value: [20, 0],
        easing: 'easeInOutSine',
    },
    duration: 1000,
    easing: 'easeInOutQuad',
    autoplay: false,
    delay: 400,
});
var slideInBtn = anime({
    targets: '.home-intro__btn',
    opacity: [0, 1],
    translateY: [20, 0],
    duration: 1000,
    easing: 'easeInOutQuad',
    autoplay: false,
    delay: 600,
});
var slideInImg = anime({
    targets: '.home-engagement__container .home-engagement__image',
    translateX: {
        value: [-50, 0],
        duration: 200,
    },
    rotate: {
        value: [-20, 0],
        duration: 900,
        easing: 'easeInOutSine',
    },
    scale: {
        value: [0.8, 1],
        duration: 1000,
        easing: 'easeInOutSine',
    },
    opacity: [0, 1],
    duration: 1200,
    easing: 'easeInOutQuad',
    autoplay: false,
    delay: anime.stagger(500, { start: 100 }),
});
var appearBox = anime({
    targets: '.home-engagement__box',
    opacity: {
        value: [0, 1],
    },
    duration: 900,
    easing: 'easeInOutQuad',
    autoplay: false,
    delay: anime.stagger(500, { start: 700 }),
});
export function initHomeAnimations(focusService, reducedMotionQuery) {
    var handler = function (entries) {
        for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
            var entry = entries_1[_i];
            var target = entry.target;
            var disableAnimations = focusService.isNavigatingWithKeyboard ||
                reducedMotionQuery.shouldNotAnimate() ||
                target.hasAttribute('data-no-animation');
            if (entry.isIntersecting &&
                target.classList.contains('scroll-container')) {
                if (target.classList.contains('home-intro__inner')) {
                    if (disableAnimations) {
                        slideIn.seek(slideIn.duration);
                        slideInText.seek(slideInText.duration);
                        slideInBtn.seek(slideInBtn.duration);
                    }
                    else {
                        slideIn.play();
                        slideInText.play();
                        slideInBtn.play();
                    }
                }
                if (target.classList.contains('home-engagement')) {
                    if (disableAnimations) {
                        slideInImg.seek(slideInImg.duration);
                        appearBox.seek(appearBox.duration);
                    }
                    else {
                        slideInImg.play();
                        appearBox.play();
                    }
                }
                observer.unobserve(entry.target);
            }
        }
    };
    var observerCfg = {
        root: null,
        treshold: 1,
    };
    /* By default, invokes the handler whenever:
     1. Any part of the target enters the viewport
     2. The last part of the target leaves the viewport */
    var observer = new IntersectionObserver(handler, observerCfg);
    /*  If it's necessary to target only one specific element,
      the handler can be given this element to observe: */
    // const introTitle = document.getElementById('target');
    // observer.observe(introTitle);
    var containersToWatch = document.querySelectorAll('.scroll-container');
    containersToWatch.forEach(function (el) { return observer.observe(el); });
}
