var ExternalLinkHint = /** @class */ (function () {
    function ExternalLinkHint() {
        var _this = this;
        this.blacklistSelectors = [
            '.social-media-sharing__link',
            '.footer-social-media__link',
            '.social-media__link',
        ];
        window.addEventListener('load', function () {
            _this.getElements();
            _this.addHintToExternalLinks();
        });
    }
    ExternalLinkHint.prototype.getElements = function () {
        this.linkElements = Array.from(document.querySelectorAll('a[href]'));
        this.externalTextTemplate = document.getElementById('external-link-hint-template');
    };
    ExternalLinkHint.prototype.addHintToExternalLinks = function () {
        for (var _i = 0, _a = this.linkElements; _i < _a.length; _i++) {
            var linkElement = _a[_i];
            if (this.isBlacklisted(linkElement)) {
                continue;
            }
            var url = new URL(linkElement.href);
            var host = window.location.host;
            if (url.host !== host) {
                this.appendExternalLinkHint(linkElement);
            }
        }
    };
    ExternalLinkHint.prototype.isBlacklisted = function (element) {
        for (var _i = 0, _a = this.blacklistSelectors; _i < _a.length; _i++) {
            var selector = _a[_i];
            if (element.matches(selector)) {
                return true;
            }
        }
        return false;
    };
    ExternalLinkHint.prototype.appendExternalLinkHint = function (linkElement) {
        var hintElement = document.importNode(this.externalTextTemplate.content, true);
        linkElement.appendChild(hintElement);
    };
    return ExternalLinkHint;
}());
export { ExternalLinkHint };
new ExternalLinkHint();
