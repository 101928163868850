import { BehaviorSubject } from 'rxjs';
var ModalRef = /** @class */ (function () {
    function ModalRef(outlet, id) {
        this.outlet = outlet;
        this.id = id;
        this.result$ = new BehaviorSubject(null);
    }
    ModalRef.prototype.close = function () {
        this.outlet.close(this.id);
        this.result$.next(this._result);
    };
    ModalRef.prototype.setResult = function (result) {
        this._result = result;
    };
    Object.defineProperty(ModalRef.prototype, "result", {
        get: function () {
            return this.result$.asObservable();
        },
        enumerable: false,
        configurable: true
    });
    return ModalRef;
}());
export { ModalRef };
