var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { PrivacyService } from './privacy-service';
import { Overlay } from '../overlay';
import { Provider } from 'libs/provider';
import { trackingHandler } from 'libs/tracking/base';
var PrivacyOverlay = /** @class */ (function (_super) {
    __extends(PrivacyOverlay, _super);
    function PrivacyOverlay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PrivacyOverlay.prototype.getDependencies = function () {
        this.privacyService = Provider.get(PrivacyService);
        this.overlay = Provider.get(Overlay);
    };
    PrivacyOverlay.prototype.onInit = function () {
        this.allowAllCta = this.host.querySelector('button[name="allow-all"]');
        this.disallowAllCta = this.host.querySelector('button[name="disallow-all"]');
        this.setUpListeners();
    };
    PrivacyOverlay.prototype.setUpListeners = function () {
        var _this = this;
        this.allowAllCta.addEventListener('click', function (event) {
            event.preventDefault();
            trackingHandler.allowStatistics = true;
            trackingHandler.allowUserPreferences = true;
            trackingHandler.allowMarketing = true;
            _this.privacyService.acceptPrivacyPolicy();
        });
        this.disallowAllCta.addEventListener('click', function (event) {
            event.preventDefault();
            trackingHandler.allowStatistics = false;
            trackingHandler.allowUserPreferences = false;
            trackingHandler.allowMarketing = false;
            _this.privacyService.acceptPrivacyPolicy();
        });
    };
    return PrivacyOverlay;
}(Component));
ComponentRegistry.register('.privacy-overlay', PrivacyOverlay);
