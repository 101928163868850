var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { ExpandableController } from 'libs/expandable-controller';
var ExpandableTableListRow = /** @class */ (function (_super) {
    __extends(ExpandableTableListRow, _super);
    function ExpandableTableListRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExpandableTableListRow.prototype.onInit = function () {
        this.getElements();
        this.setupExpandableController();
        this.setupA11y();
    };
    ExpandableTableListRow.prototype.getElements = function () {
        this.nameElement = this.host.querySelector('.table-list-row__name');
        this.buttonElement = this.host.querySelector('.table-list-row__button');
        this.expandableElement = this.host.querySelector('.table-list-row__expandable');
        this.contentElement = this.host.querySelector('.table-list-row__content');
    };
    ExpandableTableListRow.prototype.setupExpandableController = function () {
        var _this = this;
        this.expandableController = new ExpandableController({
            uid: this.componentId.toString(),
            toggler: this.buttonElement,
            expandable: this.expandableElement,
            expandableContent: this.contentElement,
        });
        this.expandableController.onStateChange.subscribe(function (state) {
            _this.updateHostClass(state.isExpanded);
        });
        this.expandableController.start();
    };
    ExpandableTableListRow.prototype.setupA11y = function () {
        var nameId = "".concat(this.componentId, "_name");
        this.nameElement.setAttribute('id', nameId);
        this.expandableElement.setAttribute('aria-labelledby', nameId);
    };
    ExpandableTableListRow.prototype.updateHostClass = function (isExpanded) {
        this.hostClass('table-list-row--expanded', isExpanded);
    };
    return ExpandableTableListRow;
}(Component));
export { ExpandableTableListRow };
ComponentRegistry.register('.table-list-row--expandable', ExpandableTableListRow);
