var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { BehaviorSubject } from 'rxjs';
var RadioGroup = /** @class */ (function (_super) {
    __extends(RadioGroup, _super);
    function RadioGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.radios = [];
        _this.onChange = new BehaviorSubject({
            target: null,
        });
        return _this;
    }
    RadioGroup.prototype.onInit = function () {
        this.getRadios();
        this.registerListeners();
    };
    RadioGroup.prototype.getRadios = function () {
        this.radios = this.getChildComponents('.radio');
    };
    RadioGroup.prototype.registerListeners = function () {
        var _this = this;
        this.radios.forEach(function (radio) {
            if (radio.isChecked) {
                _this.current = radio;
            }
            radio.onChecked.subscribe(function () {
                var _a;
                if (radio !== _this.current) {
                    (_a = _this.current) === null || _a === void 0 ? void 0 : _a.uncheck();
                    _this.current = radio;
                    _this.onChange.next({
                        target: _this.current,
                    });
                }
            });
        });
    };
    return RadioGroup;
}(Component));
export { RadioGroup };
ComponentRegistry.register('.radio-field', RadioGroup);
