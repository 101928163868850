var TooltipRef = /** @class */ (function () {
    function TooltipRef(outlet, id) {
        this.outlet = outlet;
        this.id = id;
    }
    TooltipRef.prototype.hide = function () {
        this.outlet.hide(this.id);
    };
    return TooltipRef;
}());
export { TooltipRef };
