var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { EasingFunctions } from 'libs/easing';
var ProjectProgress = /** @class */ (function (_super) {
    __extends(ProjectProgress, _super);
    function ProjectProgress() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectProgress.prototype.calculatePath = function (perc, width, height) {
        if (width === void 0) { width = 236; }
        if (height === void 0) { height = 236; }
        var full = Math.floor(perc / 25);
        var fact = (perc % 25) / 25;
        var fullPaths = [
            "L".concat(width, ",0"),
            "L".concat(width, ",").concat(height),
            "L0,".concat(height),
            "L0,0",
        ];
        var partPaths = [
            "L".concat(width * fact, ",0"),
            "L".concat(width, ",").concat(height * fact),
            "L".concat((1 - fact) * width, ",").concat(height),
            "L0,".concat((1 - fact) * height),
            "",
        ];
        return "M".concat(width / 2, ", ").concat(height / 2, " L0,0 ").concat(fullPaths
            .slice(0, full)
            .join(''), " ").concat(partPaths[full], " z");
    };
    ProjectProgress.prototype.onInit = function () {
        var _this = this;
        var projectId = this.host.getAttribute('data-id');
        if (projectId) {
            var isAnimated_1 = parseInt(this.host.getAttribute('data-animated'), 10);
            var timeMaskPath_1 = this.host.querySelector('#time_mask_path-' + projectId);
            var timeProgress_1 = parseInt(this.host.getAttribute('data-time'), 10);
            var placeMaskPath_1 = this.host.querySelector('#place_mask_path-' + projectId);
            var placeProgress_1 = parseInt(this.host.getAttribute('data-places'), 10);
            var text_1 = this.host.querySelector('.project-progress__text');
            if (isAnimated_1) {
                timeMaskPath_1.setAttribute('d', this.calculatePath(100));
                placeMaskPath_1.setAttribute('d', this.calculatePath(100));
                text_1.textContent = "".concat(0, "%");
                var observer_1 = new IntersectionObserver(function (entries) {
                    if (entries[0].isIntersecting) {
                        var start_1 = performance.now();
                        var animate_1 = function () {
                            var now = performance.now();
                            var factor = EasingFunctions.easeOutQuad(Math.min((now - start_1) / isAnimated_1, 1));
                            var time_left = (100 - timeProgress_1) * (1 - factor);
                            var places_left = (100 - placeProgress_1) * (1 - factor);
                            timeMaskPath_1.setAttribute('d', _this.calculatePath(timeProgress_1 + time_left));
                            placeMaskPath_1.setAttribute('d', _this.calculatePath(placeProgress_1 + places_left));
                            text_1.textContent = "".concat(Math.round((100 - placeProgress_1) * factor), "%");
                            if (factor < 1) {
                                requestAnimationFrame(animate_1);
                            }
                        };
                        requestAnimationFrame(animate_1);
                        observer_1.disconnect();
                    }
                }, {
                    threshold: 1,
                });
                observer_1.observe(this.host);
            }
            else {
                timeMaskPath_1.setAttribute('d', this.calculatePath(timeProgress_1));
                placeMaskPath_1.setAttribute('d', this.calculatePath(placeProgress_1));
                text_1.textContent = "".concat(100 - placeProgress_1, "%");
            }
        }
    };
    return ProjectProgress;
}(Component));
ComponentRegistry.register('.project-progress', ProjectProgress);
