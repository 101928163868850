var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var LogoUploader = /** @class */ (function (_super) {
    __extends(LogoUploader, _super);
    function LogoUploader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileReader = new FileReader();
        _this.currentFile = null;
        _this.imageUrl = null;
        _this.onFileChange = function () { return __awaiter(_this, void 0, void 0, function () {
            var files;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        files = this.input.files;
                        if (!files) return [3 /*break*/, 2];
                        this.currentFile = files[0];
                        return [4 /*yield*/, this.readImageAsUrl()];
                    case 1:
                        _a.sent();
                        this.displayPreview();
                        return [3 /*break*/, 3];
                    case 2:
                        this.currentFile = null;
                        this.imageUrl = null;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.onDragOver = function (event) {
            event.stopPropagation();
            event.preventDefault();
        };
        _this.onDrop = function (event) {
            var _a;
            event.stopPropagation();
            event.preventDefault();
            var files = (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.files;
            if ((files === null || files === void 0 ? void 0 : files.length) || 0 > 0) {
                _this.input.files = files || null;
                _this.onFileChange();
            }
        };
        return _this;
    }
    LogoUploader.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    LogoUploader.prototype.onInit = function () {
        var _this = this;
        this.dropzone = this.host.querySelector('.logo-uploader-dropzone');
        this.input = this.host.querySelector('.logo-uploader-dropzone__input');
        this.input.addEventListener('change', this.onFileChange);
        this.preview = this.host.querySelector('.logo-uploader-dropzone__preview');
        this.focusService.onFocusChange.subscribe(function () {
            if (document.activeElement === _this.input) {
                _this.focusService.showFocusAt(_this.dropzone);
            }
        });
        this.dropzone.addEventListener('dragover', this.onDragOver);
        this.dropzone.addEventListener('drop', this.onDrop);
    };
    LogoUploader.prototype.readImageAsUrl = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.fileReader.addEventListener('load', function () {
                _this.imageUrl = _this.fileReader.result;
                resolve();
            }, { once: true });
            _this.fileReader.readAsDataURL(_this.currentFile);
        });
    };
    LogoUploader.prototype.displayPreview = function () {
        if (this.imageUrl) {
            this.preview.setAttribute('src', this.imageUrl);
        }
    };
    return LogoUploader;
}(Component));
export { LogoUploader };
ComponentRegistry.register('.logo-uploader', LogoUploader);
