var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y/focus.service';
import { FocusNavigator } from 'libs/a11y/focus-navigator';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var LanguageSwitch = /** @class */ (function (_super) {
    __extends(LanguageSwitch, _super);
    function LanguageSwitch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isOpen = false;
        return _this;
    }
    LanguageSwitch.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    LanguageSwitch.prototype.onInit = function () {
        var _this = this;
        this.opener = this.host.querySelector('.language-switch__opener');
        this.closer = this.host.querySelector('.language-switch__closer');
        this.links =
            this.host.querySelectorAll('.language-link');
        this.focusNavigator = new FocusNavigator(this.links);
        window.addEventListener('keydown', function (event) {
            if (_this.isOpen) {
                switch (event.code) {
                    case 'Escape':
                        _this.close();
                        _this.opener.focus();
                        break;
                    case 'ArrowUp':
                        event.preventDefault();
                        event.stopPropagation();
                        _this.focusNavigator.previous();
                        break;
                    case 'ArrowDown':
                        event.preventDefault();
                        event.stopPropagation();
                        _this.focusNavigator.next();
                        break;
                }
            }
        });
        this.focusService.onFocusChange.subscribe(function (event) {
            var _a;
            if (event.target === _this.opener) {
                _this.focusService.showFocusAt(_this.opener, {
                    padding: 5,
                });
            }
            // if focus travels outside of language-switch
            if (!((_a = event.target) === null || _a === void 0 ? void 0 : _a.closest('.language-switch'))) {
                _this.close();
            }
        });
        this.opener.addEventListener('click', function () {
            _this.toggle();
        });
        this.closer.addEventListener('click', this.close.bind(this));
    };
    LanguageSwitch.prototype.toggle = function () {
        if (this.isOpen) {
            this.close();
        }
        else {
            this.open();
        }
    };
    LanguageSwitch.prototype.open = function () {
        this.hostClass('language-switch--open', true);
        this.opener.tabIndex = -1;
        this.opener.setAttribute('aria-expanded', 'true');
        this.isOpen = true;
        this.focusSelectedLink();
    };
    LanguageSwitch.prototype.focusSelectedLink = function () {
        var _this = this;
        this.links.forEach(function (link) {
            if (link.getAttribute('aria-checked') === 'true') {
                link.focus();
                _this.focusNavigator.detectFocusedItem();
            }
        });
    };
    LanguageSwitch.prototype.close = function () {
        this.hostClass('language-switch--open', false);
        this.opener.tabIndex = 0;
        this.opener.setAttribute('aria-expanded', 'false');
        this.isOpen = false;
    };
    return LanguageSwitch;
}(Component));
ComponentRegistry.register('.language-switch', LanguageSwitch);
