var FocusScroller = /** @class */ (function () {
    function FocusScroller(scrollContainer, region) {
        this.blacklist = [];
        this.setScrollContainer(scrollContainer);
        if (region) {
            this.setVisibleRegion(region);
        }
        else {
            this.setVisibleRegion({
                top: 0,
                bottom: 0,
            });
        }
    }
    FocusScroller.prototype.exclude = function () {
        var _a;
        var selectors = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            selectors[_i] = arguments[_i];
        }
        (_a = this.blacklist).push.apply(_a, selectors);
    };
    FocusScroller.prototype.scrollTo = function (element) {
        if (this.shouldIgnore(element)) {
            return;
        }
        var clientRect = element.getBoundingClientRect();
        if (clientRect.top < this.visibleRegion.top ||
            clientRect.bottom > window.innerHeight - this.visibleRegion.bottom) {
            this.scrollContainer.scrollTo({
                top: clientRect.top + window.pageYOffset - this.visibleRegion.top,
            });
        }
    };
    FocusScroller.prototype.shouldIgnore = function (element) {
        for (var _i = 0, _a = this.blacklist; _i < _a.length; _i++) {
            var selector = _a[_i];
            if (element.closest(selector)) {
                return true;
            }
        }
        return false;
    };
    FocusScroller.prototype.setScrollContainer = function (scrollContainer) {
        this.scrollContainer = scrollContainer;
    };
    FocusScroller.prototype.setVisibleRegion = function (region) {
        this.visibleRegion = region;
    };
    return FocusScroller;
}());
export { FocusScroller };
