var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Component, ComponentIntersectionObserver, ComponentRegistry, } from 'libs/components';
var intersectionObserver = new ComponentIntersectionObserver();
var ProjectTeaserListByCategory = /** @class */ (function (_super) {
    __extends(ProjectTeaserListByCategory, _super);
    function ProjectTeaserListByCategory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectTeaserListByCategory.prototype.onInit = function () {
        var _this = this;
        this.observedContainer = this.host;
        this.showAnimated = this.host.hasAttribute('data-animated');
        if (!this.host.classList.contains('project_category--loaded')) {
            intersectionObserver.observe(this);
        }
        this.host.addEventListener('filter_list', (function (event) {
            _this.loadContent(event.detail);
        }));
    };
    ProjectTeaserListByCategory.prototype.onIntersectionStateChange = function (entry) {
        if (entry.isIntersecting) {
            this.loadContent(null);
            intersectionObserver.unobserve(this);
        }
    };
    ProjectTeaserListByCategory.prototype.loadContent = function (queryString) {
        var _this = this;
        if (!queryString) {
            var formData = new FormData(document.querySelector('.project-filters .filter-collapsible'));
            queryString = __spreadArray(__spreadArray([], __spreadArray([], formData.getAll('filter-category'), true).map(function (x) { return "filter-category=".concat(x); }), true), __spreadArray([], formData.getAll('filter-region'), true).map(function (x) { return "filter-region=".concat(x); }), true).join('&');
        }
        var url = "".concat(this.host.getAttribute('data-url'), "&v=1.0&").concat(queryString);
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                var doc = new DOMParser().parseFromString(xhttp.responseText, 'text/html');
                var container_1 = doc.querySelector('.project_category');
                if (_this.showAnimated) {
                    container_1.classList.add('project_category--animated', 'project_category--before-animation-start');
                }
                requestAnimationFrame(function () {
                    _this.host.replaceWith(container_1);
                    requestAnimationFrame(function () {
                        container_1.classList.remove('project_category--before-animation-start');
                    });
                });
            }
        };
        xhttp.open('GET', url, true);
        xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhttp.send();
    };
    return ProjectTeaserListByCategory;
}(Component));
ComponentRegistry.register('.project_category[data-url]', ProjectTeaserListByCategory);
