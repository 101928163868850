var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var NextSteps = /** @class */ (function (_super) {
    __extends(NextSteps, _super);
    function NextSteps() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.previousWindowWidth = 0;
        _this.previousDocumentHeight = 0;
        _this.loop = function () {
            var windowWidthChanged = _this.previousWindowWidth !== window.innerWidth;
            var documentHeightChanged = document.body.scrollHeight !== _this.previousDocumentHeight;
            if (windowWidthChanged || documentHeightChanged) {
                _this.render();
                _this.previousWindowWidth = window.innerWidth;
                _this.previousDocumentHeight = document.body.scrollHeight;
            }
            window.requestAnimationFrame(_this.loop);
        };
        return _this;
    }
    NextSteps.prototype.onInit = function () {
        this.getElements();
        this.createLine();
        window.requestAnimationFrame(this.loop);
    };
    NextSteps.prototype.getElements = function () {
        this.items = Array.from(this.host.querySelectorAll('.next-steps__step'));
    };
    NextSteps.prototype.createLine = function () {
        this.line = document.createElement('div');
        this.line.classList.add('next-steps__line');
        this.host.prepend(this.line);
    };
    NextSteps.prototype.render = function () {
        var lastItem = this.items[this.items.length - 1];
        var lastItemRect = lastItem.getBoundingClientRect();
        var parentRect = this.host.getBoundingClientRect();
        this.line.style.bottom = "".concat(parentRect.bottom - lastItemRect.top - 1, "px");
    };
    return NextSteps;
}(Component));
export { NextSteps };
ComponentRegistry.register('.next-steps', NextSteps);
