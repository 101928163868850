import { ElementMutationObserver } from './element-mutation-observer';
import { DefaultIdGenerator } from './id-generator';
var ComponentRegistry = /** @class */ (function () {
    function ComponentRegistry() {
    }
    ComponentRegistry.setIdGenerator = function (idGenerator) {
        this.idGenerator = idGenerator;
    };
    ComponentRegistry.register = function (selector, componentClass) {
        if (this.registrations.has(selector)) {
            throw new Error("Trying to register multiple classes on the same selector '".concat(selector, "'"));
        }
        else {
            this.registrations.set(selector, componentClass);
        }
    };
    ComponentRegistry.create = function (host, componentClass) {
        var id = this.idGenerator.next();
        host.dataset['componentId'] = "".concat(id);
        var instance = new componentClass(host, id);
        instance.onCreate();
        this.instances.set(id, instance);
        return instance;
    };
    ComponentRegistry.get = function (id) {
        return this.instances.get(id);
    };
    ComponentRegistry.attachComponents = function (hostElements) {
        var createdInstances = this.createInstances(function () { return hostElements; });
        this.initComponents(createdInstances);
    };
    ComponentRegistry.attachAllComponentsUnder = function (root) {
        var createdInstances = this.createInstances(function (selector) {
            return Array.from(root.querySelectorAll(selector));
        });
        this.initComponents(createdInstances);
    };
    ComponentRegistry.observe = function (observerRoot) {
        var _this = this;
        if (observerRoot === void 0) { observerRoot = document.body; }
        this.mutationObserver = new ElementMutationObserver(observerRoot);
        this.mutationObserver.onElementsAdded(function (addedElements) {
            _this.attachComponents(addedElements);
        });
        var startTime = performance.now();
        this.attachAllComponentsUnder(observerRoot);
        console.log("Creating components took ".concat(performance.now() - startTime, "ms"));
    };
    ComponentRegistry.createInstances = function (getHostElements) {
        var _this = this;
        var createdInstances = [];
        this.registrations.forEach(function (component, selector) {
            for (var _i = 0, _a = getHostElements(selector); _i < _a.length; _i++) {
                var element = _a[_i];
                // only register on elements that haven't already been used and that
                // match selector
                if (element.dataset['componentId'] === undefined &&
                    element.matches(selector)) {
                    _this.attachAllComponentsUnder(element);
                    createdInstances.push(_this.create(element, component));
                }
            }
        });
        return createdInstances;
    };
    ComponentRegistry.initComponents = function (createdComponents) {
        // After ALL components were created
        window.requestAnimationFrame(function () {
            for (var _i = 0, createdComponents_1 = createdComponents; _i < createdComponents_1.length; _i++) {
                var instance = createdComponents_1[_i];
                instance.getDependencies();
                instance.onInit();
            }
        });
    };
    ComponentRegistry.registrations = new Map();
    ComponentRegistry.instances = new Map();
    return ComponentRegistry;
}());
export { ComponentRegistry };
// NOTE: Just the default behavior for auto setup when this lib is used.
// can be changed per project if necessary
ComponentRegistry.setIdGenerator(new DefaultIdGenerator());
// Can also be use for debugging purposes in the console.
window['ComponentRegistry'] = ComponentRegistry;
