var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Header.prototype.onInit = function () {
        var _this = this;
        if (!this.host.classList.contains('header--small')) {
            var mainNav_1 = document.querySelector('.main-nav');
            this.host.addEventListener('changeSize', function () {
                var isSmall = window.pageYOffset >= 80 ||
                    mainNav_1.classList.contains('main-nav--open');
                _this.hostClass('header--small', isSmall);
            });
            window.addEventListener('scroll', function () {
                return _this.host.dispatchEvent(new CustomEvent('changeSize'));
            });
        }
    };
    return Header;
}(Component));
ComponentRegistry.register('.header', Header);
