var Provider = /** @class */ (function () {
    function Provider() {
    }
    Provider.get = function (token) {
        return this.provided.get(token);
    };
    Provider.provide = function (token, value) {
        this.provided.set(token, value);
    };
    Provider.provided = new WeakMap();
    return Provider;
}());
export { Provider };
