import { Subject } from 'rxjs';
var AutoCompleteListView = /** @class */ (function () {
    function AutoCompleteListView(host, listId, focusService) {
        var _this = this;
        this.host = host;
        this.listId = listId;
        this.focusService = focusService;
        this.options = [];
        this.renderedOptions = [];
        this.isVisible = false;
        this.onSelect$ = new Subject();
        this.onHover$ = new Subject();
        this.loop = function () {
            if (_this.isVisible) {
                _this.reposition();
                _this.resize();
            }
            window.requestAnimationFrame(_this.loop);
        };
        this.createListOverlay();
        this.createDropdown();
        this.setA11yAttributes();
        this.renderOptions();
        window.requestAnimationFrame(this.loop);
    }
    AutoCompleteListView.prototype.createListOverlay = function () {
        this.overlay = document.createElement('div');
        this.overlay.classList.add('auto-complete-overlay');
        document.body.appendChild(this.overlay);
    };
    AutoCompleteListView.prototype.createDropdown = function () {
        this.dropdown = document.createElement('ul');
        this.dropdown.classList.add('auto-complete-dropdown');
        this.dropdownWrapper = document.createElement('div');
        this.dropdownWrapper.classList.add('auto-complete-dropdown-wrapper');
        this.dropdownWrapper.appendChild(this.dropdown);
        this.overlay.appendChild(this.dropdownWrapper);
    };
    AutoCompleteListView.prototype.setA11yAttributes = function () {
        this.dropdown.setAttribute('id', this.listId);
        this.dropdown.setAttribute('role', 'listbox');
    };
    AutoCompleteListView.prototype.renderOptions = function () {
        this.dropdown.innerHTML = "";
        this.renderedOptions = [];
        for (var i = 0; i < this.options.length; i++) {
            var option = this.options[i];
            this.dropdown.appendChild(this.renderOption(option, i));
        }
    };
    AutoCompleteListView.prototype.renderOption = function (option, index) {
        var _this = this;
        var button = document.createElement('button');
        button.classList.add('auto-complete-option');
        button.innerText = option.label;
        button.tabIndex = -1;
        button.addEventListener('mousedown', function (event) {
            // prevent input from losing focus on click
            event.preventDefault();
        });
        button.addEventListener('click', function (event) {
            event.preventDefault();
            _this.onSelect$.next(option);
        });
        var element = document.createElement('li');
        element.setAttribute('role', 'option');
        element.classList.add('auto-complete-dropdown__item');
        element.addEventListener('mousemove', function (event) {
            event.preventDefault();
            _this.setFocusAt(index);
            _this.onHover$.next(index);
        });
        element.appendChild(button);
        this.renderedOptions.push(element);
        return element;
    };
    AutoCompleteListView.prototype.reposition = function () {
        var offsetTop = this.host.offsetTop;
        var hostHeight = this.host.clientHeight;
        var offsetLeft = this.host.offsetLeft;
        this.dropdownWrapper.style.top = "".concat(offsetTop + hostHeight, "px");
        this.dropdownWrapper.style.left = "".concat(offsetLeft, "px");
    };
    AutoCompleteListView.prototype.resize = function () {
        var width = this.host.clientWidth;
        this.dropdown.style.width = "".concat(width, "px");
    };
    AutoCompleteListView.prototype.setOptions = function (options) {
        this.options = options;
        this.renderOptions();
    };
    AutoCompleteListView.prototype.hide = function () {
        this.setVisible(false);
    };
    AutoCompleteListView.prototype.setVisible = function (isVisible) {
        this.isVisible = isVisible;
        this.overlay.classList.toggle('auto-complete-overlay--is-visible', isVisible);
        this.dropdown.scrollTo({
            top: 0,
        });
    };
    AutoCompleteListView.prototype.markIndexAsFocused = function (index) {
        for (var i = 0; i < this.renderedOptions.length; i++) {
            var option = this.renderedOptions[i];
            var isFocused = i === index;
            this.setFocusedClass(option, isFocused);
            if (isFocused) {
                option.scrollIntoView({
                    block: 'center',
                });
                this.focusService.showFocusAt(option);
            }
        }
    };
    AutoCompleteListView.prototype.setFocusAt = function (index) {
        for (var i = 0; i < this.renderedOptions.length; i++) {
            var option = this.renderedOptions[i];
            var isFocused = i === index;
            this.setFocusedClass(option, isFocused);
        }
    };
    AutoCompleteListView.prototype.setFocusedClass = function (option, isFocused) {
        option.classList.toggle('auto-complete-dropdown__item--highlighted', isFocused);
    };
    AutoCompleteListView.prototype.onSelect = function () {
        return this.onSelect$.asObservable();
    };
    AutoCompleteListView.prototype.onHover = function () {
        return this.onHover$.asObservable();
    };
    return AutoCompleteListView;
}());
export { AutoCompleteListView };
