var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { YesNoModal } from '../modal';
import { ModalService } from '../modal/modal-service';
var LeaveCompany = /** @class */ (function (_super) {
    __extends(LeaveCompany, _super);
    function LeaveCompany() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LeaveCompany.prototype.getDependencies = function () {
        this.modalService = Provider.get(ModalService);
    };
    LeaveCompany.prototype.onInit = function () {
        this.registerLeaveListener();
    };
    LeaveCompany.prototype.registerLeaveListener = function () {
        this.setupModal('leave-company-button', 'leave-company-modal-content');
    };
    LeaveCompany.prototype.setupModal = function (buttonId, templateId) {
        var _this = this;
        var button = document.getElementById(buttonId);
        var template = document.getElementById(templateId);
        if (!button || !template) {
            return;
        }
        button.addEventListener('click', function (event) {
            event.preventDefault();
            var ref = _this.modalService.open(function (id, ref) { return new YesNoModal(id, ref, template); });
            ref.result.subscribe(function (result) {
                if (result === null || result === void 0 ? void 0 : result.hasAccepted) {
                    var form = button.closest('form');
                    form.submit();
                }
            });
        });
    };
    return LeaveCompany;
}(Component));
export { LeaveCompany };
ComponentRegistry.register('.leave-company', LeaveCompany);
