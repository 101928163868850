var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FocusService } from 'libs/a11y';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { Subject } from 'rxjs';
import { AutoCompleteInputView } from './auto-complete-input-view';
import { AutoCompleteListView } from './auto-complete-list-view';
import { AutoCompleteModel } from './auto-complete-model';
import { ListFocusTracker } from './list-focus-tracker';
var AutoComplete = /** @class */ (function (_super) {
    __extends(AutoComplete, _super);
    function AutoComplete() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onInput$ = new Subject();
        _this.onSelect$ = new Subject();
        return _this;
    }
    AutoComplete.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    AutoComplete.prototype.onInit = function () {
        this.model = new AutoCompleteModel();
        this.listFocusTracker = new ListFocusTracker();
        this.listId = "auto-complete-list_".concat(this.componentId);
        this.inputView = new AutoCompleteInputView(this.host, this.listId, this.focusService);
        this.listView = new AutoCompleteListView(this.host, this.listId, this.focusService);
        this.initialSync();
        this.addListeners();
    };
    AutoComplete.prototype.initialSync = function () {
        this.model.setValue(this.inputView.getValue());
    };
    AutoComplete.prototype.addListeners = function () {
        var _this = this;
        this.model.onStateChange().subscribe(function (change) {
            switch (change.name) {
                case 'value':
                    _this.inputView.setValue(_this.model.getValue());
                    break;
                case 'options':
                    _this.listView.setOptions(_this.model.getOptions());
                    _this.listFocusTracker.setList(_this.model.getOptions());
                    break;
            }
            _this.updateListVisiblity();
        });
        this.listFocusTracker
            .onFocusChange()
            .subscribe(function (change) {
            _this.listView.markIndexAsFocused(change.index);
        });
        this.inputView.onInput().subscribe(function (value) {
            _this.model.setValue(value);
            _this.onInput$.next(value);
            if (_this.model.getSelected()) {
                _this.checkIfSelectedIsStillValid();
            }
        });
        this.inputView.onFocus().subscribe(function () {
            _this.updateListVisiblity();
        });
        this.inputView.onBlur().subscribe(function () {
            _this.hideList();
        });
        this.inputView.onArrowUp().subscribe(function () {
            _this.listFocusTracker.up();
        });
        this.inputView.onArrowDown().subscribe(function () {
            _this.listFocusTracker.down();
        });
        this.inputView.onEnter().subscribe(function () {
            var option = _this.listFocusTracker.getCurrentItem();
            if (option) {
                _this.select(option);
            }
        });
        this.listView.onSelect().subscribe(function (option) {
            _this.select(option);
        });
        this.listView.onHover().subscribe(function (index) {
            _this.listFocusTracker.setCurrentIndex(index);
        });
    };
    AutoComplete.prototype.select = function (option) {
        this.model.setValue(option.label);
        this.model.setSelected(option);
        this.onSelect$.next(this.model.getSelected());
        this.hideList();
    };
    AutoComplete.prototype.updateListVisiblity = function () {
        var hasLongEnoughValue = this.model.getValue().length >= 3;
        var hasEnoughOptions = this.model.getOptions().length > 0;
        var shouldShowList = hasLongEnoughValue && hasEnoughOptions;
        this.listView.setVisible(shouldShowList);
        this.inputView.setExpanded(shouldShowList);
    };
    AutoComplete.prototype.checkIfSelectedIsStillValid = function () {
        var _a;
        if (this.model.getValue() !== ((_a = this.model.getSelected()) === null || _a === void 0 ? void 0 : _a.label)) {
            this.model.setSelected(null);
            this.onSelect$.next(null);
        }
    };
    AutoComplete.prototype.hideList = function () {
        this.listView.hide();
        this.inputView.setExpanded(false);
        this.inputView.focus();
        this.listFocusTracker.focusAt(-1);
    };
    AutoComplete.prototype.setSelectedValue = function (value) {
        this.model.setSelected({
            label: 'initial',
            value: value,
        });
    };
    AutoComplete.prototype.setOptions = function (options) {
        this.model.setOptions(options);
    };
    AutoComplete.prototype.onInput = function () {
        return this.onInput$.asObservable();
    };
    AutoComplete.prototype.onSelect = function () {
        return this.onSelect$.asObservable();
    };
    return AutoComplete;
}(Component));
export { AutoComplete };
ComponentRegistry.register('.auto-complete', AutoComplete);
