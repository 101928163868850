var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
var SearchToggler = /** @class */ (function (_super) {
    __extends(SearchToggler, _super);
    function SearchToggler() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SearchToggler.prototype.onInit = function () {
        var _this = this;
        this.opener = this.host.querySelector('.search-toggler__opener');
        this.closer = this.host.querySelector('.search-toggler__closer');
        this.search = document.querySelector('.search');
        this.searchInput = this.search.querySelector('input[name="q"]');
        this.pathArray = window.location.pathname.split('/');
        var location = this.pathArray[2];
        if (location !== 'search') {
            this.opener.addEventListener('click', function () {
                _this.hostClass('search-toggler--active', true);
                _this.search.classList.add('search--active');
                _this.searchInput.focus();
            });
            this.closer.addEventListener('click', function () {
                _this.search.classList.remove('search--active');
                _this.hostClass('search-toggler--active', false);
                _this.searchInput.value = '';
            });
        }
    };
    return SearchToggler;
}(Component));
ComponentRegistry.register('.search-toggler', SearchToggler);
