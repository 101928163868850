var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { FocusService } from 'libs/a11y';
import { Component, ComponentIntersectionObserver, ComponentRegistry, } from 'libs/components';
import { Provider } from 'libs/provider';
var intersectionObserver = new ComponentIntersectionObserver();
var ProjectTeaserList = /** @class */ (function (_super) {
    __extends(ProjectTeaserList, _super);
    function ProjectTeaserList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectTeaserList.prototype.getDependencies = function () {
        this.focusService = Provider.get(FocusService);
    };
    ProjectTeaserList.prototype.onInit = function () {
        var _this = this;
        this.observedContainer = this.host;
        this.showAnimated = this.host.hasAttribute('data-animated');
        if (!this.host.classList.contains('project_list--loaded')) {
            intersectionObserver.observe(this);
        }
        this.host.addEventListener('filter_list', (function (event) {
            _this.loadContent(event.detail);
        }));
        this.currentPage = parseInt(this.host.getAttribute('data-page'), 10);
        this.totalPages = parseInt(this.host.getAttribute('data-pages'), 10);
        this.loadMoreButton = this.host.nextElementSibling.querySelector('.load_more_projects');
        if (this.loadMoreButton) {
            this.loadMoreEventListener = function (event) {
                event.preventDefault();
                _this.loadMore();
            };
            this.loadMoreButton.addEventListener('click', this.loadMoreEventListener);
        }
        this.checkPagination();
    };
    ProjectTeaserList.prototype.onIntersectionStateChange = function (entry) {
        if (entry.isIntersecting) {
            this.loadContent(null);
            intersectionObserver.unobserve(this);
        }
    };
    ProjectTeaserList.prototype.checkPagination = function () {
        if (this.loadMoreButton) {
            console.log(this.currentPage, this.totalPages, this.totalPages > this.currentPage);
            if (this.currentPage &&
                this.totalPages &&
                this.totalPages > this.currentPage) {
                this.loadMoreButton.classList.add('load_more_projects--show');
            }
            else {
                this.loadMoreButton.classList.remove('load_more_projects--show');
            }
        }
    };
    ProjectTeaserList.prototype.loadMore = function () {
        if (this.loadMoreButton.classList.contains('load_more_projects--show')) {
            var formData = new FormData(document.querySelector('.project-filters .filter-collapsible'));
            var queryString = __spreadArray(__spreadArray(__spreadArray([], __spreadArray([], formData.getAll('filter-category'), true).map(function (x) { return "filter-category=".concat(x); }), true), __spreadArray([], formData.getAll('filter-region'), true).map(function (x) { return "filter-region=".concat(x); }), true), ["page=".concat(this.currentPage + 1)], false).join('&');
            this.loadContent(queryString);
        }
    };
    ProjectTeaserList.prototype.loadContent = function (queryString) {
        var _this = this;
        var url = this.host.getAttribute('data-url');
        if (queryString) {
            url += '?v=1.0&' + queryString;
        }
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                var doc = new DOMParser().parseFromString(xhttp.responseText, 'text/html');
                var container_1 = doc.querySelector('.project_list');
                var nextPageNumber = parseInt(container_1.getAttribute('data-page'), 10);
                if (nextPageNumber && nextPageNumber > _this.currentPage) {
                    var items = container_1.querySelectorAll('.project_list__item');
                    for (var idx = 0; idx < items.length; idx++) {
                        _this.host.appendChild(items[idx]);
                    }
                    _this.currentPage = nextPageNumber;
                    _this.totalPages = parseInt(container_1.getAttribute('data-pages'), 10);
                    ComponentRegistry.attachAllComponentsUnder(_this.host);
                    _this.checkPagination();
                }
                else {
                    if (_this.showAnimated &&
                        !_this.focusService.isNavigatingWithKeyboard) {
                        container_1.classList.add('project_list--animated', 'project_list--before-animation-start');
                    }
                    requestAnimationFrame(function () {
                        if (_this.loadMoreButton) {
                            _this.loadMoreButton.removeEventListener('click', _this.loadMoreEventListener);
                        }
                        ComponentRegistry.attachAllComponentsUnder(container_1);
                        _this.host.replaceWith(container_1);
                        requestAnimationFrame(function () {
                            container_1.classList.remove('project_list--before-animation-start');
                        });
                    });
                }
            }
        };
        xhttp.open('GET', url, true);
        xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhttp.send();
    };
    return ProjectTeaserList;
}(Component));
ComponentRegistry.register('.project_list[data-url]', ProjectTeaserList);
