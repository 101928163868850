import { trackingHandler } from './base';
/**
 * Handle internal tracked links
 */
var trackedButtons = document.querySelectorAll('.btn,.nav-list__link,.footer-nav__link,.home-engagement__container,[data-cta-name]');
export function setupTracking(trackedButtons) {
    var _loop_2 = function (idx) {
        var host = trackedButtons[idx];
        trackedButtons[idx].addEventListener('click', function () {
            var ctaName = host.textContent;
            var innerParagraph = host.querySelector('p');
            if (host.dataset['ctaName']) {
                ctaName = host.dataset['ctaName'];
            }
            else if (innerParagraph) {
                ctaName = innerParagraph.textContent;
            }
            digitalData['CTAClick'] = {};
            digitalData['CTAClick']['CTAName'] = ctaName;
            digitalData['CTAClick']['CTALink'] = host.getAttribute('href');
            trackingHandler.satelliteTrack('Site CTA Click', digitalData.CTAClick);
        });
    };
    for (var idx = 0; idx < trackedButtons.length; idx++) {
        _loop_2(idx);
    }
}
setupTracking(trackedButtons);
/**
 * Handle tracking for all external links
 * External is determined based on the start of the link.
 */
var trackedLinks = document.querySelectorAll('a[href]');
var _loop_1 = function (idx) {
    var host = trackedLinks[idx];
    host.addEventListener('click', function () {
        var href = host.getAttribute('href');
        if (href.startsWith('/de') ||
            href.startsWith('/fr') ||
            href.startsWith('/it') ||
            href.startsWith('/en')) {
            return;
        }
        if (href.startsWith('#')) {
            digitalData['anchor'] = {};
            digitalData['anchor']['text'] = host.textContent;
            trackingHandler.satelliteTrack('Site Anchor Link Click', digitalData.anchor);
        }
        else if (href.indexOf(window.location.host) < 0) {
            digitalData['ExitLink'] = {};
            digitalData['ExitLink']['ExitURL'] = href;
            trackingHandler.satelliteTrack('Site Exit Link', digitalData.ExitLink);
        }
    });
};
for (var idx = 0; idx < trackedLinks.length; idx++) {
    _loop_1(idx);
}
