var TooltipFactory = /** @class */ (function () {
    function TooltipFactory(template, cssClass, position) {
        this.template = template;
        this.cssClass = cssClass;
        this.position = position;
    }
    TooltipFactory.prototype.create = function () {
        var contentNode = this.template.content.cloneNode(true);
        var element = document.createElement('div');
        element.classList.add('tooltip');
        element.classList.add("tooltip--".concat(this.position));
        var inner = document.createElement('div');
        inner.classList.add('tooltip__inner');
        inner.appendChild(contentNode);
        var arrow = document.createElement('div');
        arrow.classList.add('tooltip__arrow');
        element.appendChild(arrow);
        element.appendChild(inner);
        if (this.cssClass) {
            var classes = this.cssClass.split(/\s/);
            for (var _i = 0, classes_1 = classes; _i < classes_1.length; _i++) {
                var cssClass = classes_1[_i];
                element.classList.add(cssClass);
            }
        }
        return element;
    };
    return TooltipFactory;
}());
export { TooltipFactory };
