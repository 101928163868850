var ElementMutationObserver = /** @class */ (function () {
    function ElementMutationObserver(observerRoot) {
        this.observerRoot = observerRoot;
    }
    ElementMutationObserver.prototype.onElementsAdded = function (callback) {
        var _this = this;
        var mutationObserver = new MutationObserver(function (mutations) {
            var addedElements = _this.getAddedElements(mutations);
            if (addedElements.length > 0) {
                callback(addedElements);
            }
        });
        mutationObserver.observe(this.observerRoot, {
            childList: true,
            subtree: true,
        });
    };
    ElementMutationObserver.prototype.getAddedElements = function (mutations) {
        var _a;
        var nodes = mutations
            .filter(function (mutation) { var _a; return ((_a = mutation.addedNodes) === null || _a === void 0 ? void 0 : _a.length) > 0; })
            .map(function (mutation) {
            var elements = [];
            for (var i = 0; i < mutation.addedNodes.length; i++) {
                var node = mutation.addedNodes[i];
                if (node instanceof HTMLElement) {
                    elements.push(node);
                }
            }
            return elements;
        })
            .flat(1);
        // flatten array of nodes
        return (_a = []).concat.apply(_a, nodes);
    };
    return ElementMutationObserver;
}());
export { ElementMutationObserver };
