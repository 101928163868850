var Modal = /** @class */ (function () {
    function Modal(id, ref) {
        this.id = id;
        this.ref = ref;
        this.host = document.createElement('div');
        this.host.classList.add('modal');
    }
    Modal.prototype.onActivate = function () {
        // hook
    };
    Modal.prototype.onDeactivate = function () {
        // hook
    };
    return Modal;
}());
export { Modal };
