import { filter, Subject } from 'rxjs';
import { TextInputLogger } from './text-input-logger';
var ListJumper = /** @class */ (function () {
    function ListJumper() {
        this.list = [];
        this.onJump = new Subject();
        this.setupListeners();
    }
    ListJumper.prototype.setupListeners = function () {
        var _this = this;
        this.textInputLogger = new TextInputLogger();
        this.textInputLogger.onChange
            .pipe(filter(Boolean))
            .subscribe(function (currentInput) {
            if (currentInput) {
                _this.jumpToBestMatch(currentInput);
            }
        });
        this.onSearchChange = this.textInputLogger.onChange;
        this.onDone = this.textInputLogger.onDone;
    };
    ListJumper.prototype.jumpToBestMatch = function (input) {
        for (var i = 0; i < this.list.length; i++) {
            var item = this.list[i];
            if (item.startsWith(input)) {
                this.onJump.next(i);
                return;
            }
        }
    };
    ListJumper.prototype.setList = function (list) {
        if (list === void 0) { list = []; }
        this.list = list.map(function (item) { return item.toLowerCase(); });
    };
    ListJumper.prototype.activate = function () {
        this.textInputLogger.activate();
    };
    ListJumper.prototype.deactivate = function () {
        this.textInputLogger.deactivate();
    };
    return ListJumper;
}());
export { ListJumper };
