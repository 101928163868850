import { tabbable } from 'tabbable';
var MainNavBehavior = /** @class */ (function () {
    function MainNavBehavior(host, focusService) {
        var _this = this;
        this.host = host;
        this.focusService = focusService;
        this.isActive = false;
        this.tabbables = [];
        this.isOpen = false;
        this.getElements();
        this.tabbables = tabbable(this.inner);
        this.navOpener.addEventListener('click', function () {
            if (_this.isActive) {
                if (_this.isOpen) {
                    _this.close();
                }
                else {
                    _this.open();
                }
            }
        });
        this.navCloser.addEventListener('click', function () {
            if (_this.isActive) {
                _this.close();
            }
        });
        this.focusService.onFocusChange.subscribe(function (event) {
            var _a;
            if (_this.isActive) {
                if (_this.navOpener === event.target) {
                    _this.focusService.showFocusAt(_this.navOpener, {
                        padding: 5,
                    });
                }
                if (!((_a = event.target) === null || _a === void 0 ? void 0 : _a.closest('.main-nav'))) {
                    _this.close();
                }
            }
        });
    }
    MainNavBehavior.prototype.getElements = function () {
        this.navOpener = this.host.querySelector('.main-nav__opener');
        this.inner = this.host.querySelector('.main-nav__inner');
        this.navCloser = this.inner.querySelector('.main-nav__closer');
    };
    MainNavBehavior.prototype.open = function () {
        this.isOpen = true;
    };
    MainNavBehavior.prototype.close = function () {
        this.isOpen = false;
    };
    MainNavBehavior.prototype.activate = function () {
        this.isActive = true;
        this.close();
    };
    MainNavBehavior.prototype.deactivate = function () {
        this.isActive = false;
        this.close();
    };
    MainNavBehavior.prototype.enableLinks = function () {
        this.navOpener.setAttribute('aria-expanded', 'true');
        this.tabbables.forEach(function (tabbable) {
            tabbable.tabIndex = 0;
        });
    };
    MainNavBehavior.prototype.disableLinks = function () {
        this.navOpener.setAttribute('aria-expanded', 'false');
        this.tabbables.forEach(function (tabbable) {
            tabbable.tabIndex = -1;
        });
    };
    return MainNavBehavior;
}());
export { MainNavBehavior };
