import { Subject } from 'rxjs';
var ListFocusTracker = /** @class */ (function () {
    function ListFocusTracker() {
        this.currentIndex = -1;
        this.minIndex = 0;
        this.maxIndex = 0;
        this.list = [];
        this.onFocusChange$ = new Subject();
    }
    ListFocusTracker.prototype.setList = function (list) {
        this.list = list;
        this.currentIndex = -1;
        this.maxIndex = this.list.length - 1;
    };
    ListFocusTracker.prototype.up = function () {
        if (this.currentIndex > this.minIndex) {
            this.focusAt(this.currentIndex - 1);
        }
        else {
            this.focusAt(this.maxIndex);
        }
    };
    ListFocusTracker.prototype.down = function () {
        if (this.currentIndex < this.maxIndex) {
            this.focusAt(this.currentIndex + 1);
        }
        else {
            this.focusAt(this.minIndex);
        }
    };
    ListFocusTracker.prototype.getItem = function (index) {
        return this.list[index];
    };
    ListFocusTracker.prototype.focusAt = function (index) {
        this.currentIndex = index;
        this.onFocusChange$.next({
            index: this.currentIndex,
            item: this.getCurrentItem(),
        });
    };
    ListFocusTracker.prototype.setCurrentIndex = function (index) {
        this.currentIndex = index;
    };
    ListFocusTracker.prototype.getCurrentItem = function () {
        return this.getItem(this.currentIndex);
    };
    ListFocusTracker.prototype.onFocusChange = function () {
        return this.onFocusChange$.asObservable();
    };
    return ListFocusTracker;
}());
export { ListFocusTracker };
