var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component, ComponentRegistry } from 'libs/components';
import { ProfileNavItemSubnavController } from './profile-nav-item-subnav-controller';
var ProfileNavItem = /** @class */ (function (_super) {
    __extends(ProfileNavItem, _super);
    function ProfileNavItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProfileNavItem.prototype.onInit = function () {
        if (this.hasSubnav()) {
            this.addSubnavBehavior();
        }
    };
    ProfileNavItem.prototype.hasSubnav = function () {
        return !!this.host.querySelector('.profile-subnav');
    };
    ProfileNavItem.prototype.addSubnavBehavior = function () {
        new ProfileNavItemSubnavController(this.componentId, this.host);
    };
    return ProfileNavItem;
}(Component));
export { ProfileNavItem };
ComponentRegistry.register('.profile-nav-item', ProfileNavItem);
