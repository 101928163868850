import { ModalRef } from './modal-ref';
var ModalService = /** @class */ (function () {
    function ModalService(modalOutlet) {
        this.modalOutlet = modalOutlet;
        this.id = -1;
    }
    ModalService.prototype.open = function (modalFactoryCallback) {
        var id = ++this.id;
        var ref = new ModalRef(this.modalOutlet, id);
        var modal = modalFactoryCallback(id, ref);
        this.modalOutlet.open(modal);
        return ref;
    };
    return ModalService;
}());
export { ModalService };
