import { BehaviorSubject } from 'rxjs';
var TextInputLogger = /** @class */ (function () {
    function TextInputLogger() {
        this.isActive = false;
        this.currentInput = [];
        this.onChange = new BehaviorSubject(null);
        this.onDone = new BehaviorSubject(null);
        this.setupListeners();
    }
    TextInputLogger.prototype.setupListeners = function () {
        var _this = this;
        window.addEventListener('keydown', function (event) {
            var _a;
            if (_this.isActive) {
                var key = event.key.toLowerCase();
                // can't have space at beginning
                if (key === ' ' && _this.currentInput.length === 0) {
                    return;
                }
                // select only unicode letters
                if (((_a = key.match(/[-0-9.a-zA-ZäöüÄÖÜÉÀÈöäü]/g)) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                    _this.currentInput.push(key);
                    var text = _this.currentInputToLowerCaseString();
                    _this.onChange.next(text);
                    clearTimeout(_this.timeoutId);
                    _this.timeoutId = setTimeout(function () {
                        _this.done();
                    }, 500);
                }
            }
        });
    };
    TextInputLogger.prototype.currentInputToLowerCaseString = function () {
        return this.currentInput.map(function (input) { return input.toLowerCase(); }).join('');
    };
    TextInputLogger.prototype.done = function () {
        this.onDone.next(this.onChange.value);
        this.onChange.next(null);
        this.currentInput = [];
    };
    TextInputLogger.prototype.activate = function () {
        this.isActive = true;
    };
    TextInputLogger.prototype.deactivate = function () {
        this.isActive = false;
    };
    return TextInputLogger;
}());
export { TextInputLogger };
